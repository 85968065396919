import React, { useState } from "react";
import "./xfinity-tv-review.scss";
import SubNav from "../../../components/sub-nav/SubNav";
import { xfinityNavLinks } from "../../../assets/data/navLinks/xfinityNavLinks";
import InnerPagesHeroTwo from "../../../components/inner-pages-hero-two/InnerPagesHeroTwo";
import StickyHeader from "../../../components/sticky-header/StickyHeader";
import xfinitylogo from "../../../assets/images/xfinitylogo.png";
import star from "../../../assets/images/star.png";
import { GoArrowUpRight } from "react-icons/go";
import cross from "../../../assets/images/cross.png";
import { IoThumbsDownSharp, IoThumbsUpSharp } from "react-icons/io5";
import quotationsIcon from "../../../assets/images/quotations.svg";
import { Link } from "react-router-dom";
import CTA from "../../../components/cta-section/CTA";
import PackageTable from "../../../components/package-table/PackageTable";
import quotations from "../../../assets/images/quotations.svg";
import tvstream from "../../../assets/images/tvstream.png";
import remotereview from "../../../assets/images/remotereview.png";
import reviewmantwo from "../../../assets/images/reviewmantwo.png";
import Newsletter from "../../../components/newsletter/Newsletter";
import xone from "../../../assets/images/xone.png";
import xtwo from "../../../assets/images/xtwo.png";
import xthree from "../../../assets/images/xthree.png";

const XfinityTvReview = () => {  
  const [faq, setFaq] = useState();
  let accordionHandler = (i) => {
    if (faq === i) {
      return setFaq(null);
    }

    setFaq(i);
  };
  let heroData = {
    card1: {
      logo: xfinitylogo,
      rating: "(4.5/5)",
      starImg: star,
      desc: "Get high-speed internet for only $30/mo. when you add Xfinity Mobile to your plan.",
      buttonText: "Compare Plans",
      linkPath: "#",
      buttonIcon: <GoArrowUpRight className="check-icon" />,
    },

    card2Img: reviewmantwo,

    cardsStrip: [
      {
        subtitle: "Price:",
        title: "$20.00–$80.00/mo.",
        icon: cross,
      },
      {
        subtitle: "Upload speeds:",
        title: "125-185+",
        icon: cross,
      },
      {
        subtitle: "DVR:",
        title: "20–300 hours",
        icon: cross,
      },
      {
        subtitle: "Contract:",
        title: "1-2 years or no-term agreement",
        icon: cross,
      },
    ],

    tableData: [
      {
        head: [
          "Top packages",
          "Price* ",
          "Channels",
          "Popular channels",
          "Internet speed",
          "Details",
        ],

        body: [
          {
            content: [
              "1.",
              "Ultimate TV",
              "$68.50/mo.",
              "185+",
              "IFC, Disney XD, Nat Geo Wild",
              "N/A",
            ],
            linkPath: "#",
          },

          {
            content: [
              "2.",
              "Ultimate TV with Gigabit Extra",
              "$125.00/mo.*",
              "185+",
              "NBA TV, Disney Channel, TeenNick",
              "1,200 Mbps",
            ],
            linkPath: "#",
          },
          {
            content: [
              "3.",
              "Popular TV",
              "$50.00/mo.",
              "125+",
              "AMC, Cartoon Network, Disney Channel",
              "N/A",
            ],
            linkPath: "#",
          },
        ],
      },
    ],

    bottomDesc:
      "*For the first 12 months with a 1-year agreement.**For the first 24 months with no term agreement. Data effective as of publish date. Offers and availability may vary by location and are subject to change.",
  };
  let packageTableData = [
    {
      head: ["package", "Price", "Popular channels", "Channels", "Details"],

      body: [
        {
          content: ["1.", "Choice TV", "$20.00/mo.", "ABC, CBS, NBC", "10+"],
          linkPath: "#",
        },
        {
          content: ["2.", "Popular TV ", "$50.00/mo.", "FX, HGTV, MTV", "125+"],
          linkPath: "#",
        },
        {
          content: [
            "3.",
            "Ultimate TV",
            "$68.50/mo.",
            "Cooking Channel, DIY Network, ESPNU",
            "185+",
          ],
          linkPath: "#",
        },
      ],
    },
  ];
  return (
    <div className="xfinity-tv-review">
      <SubNav links={xfinityNavLinks} />

      <div className="section inner-hero">
        <div className="head">
          <h2 className="sect-heading">Xfinity TV Plans And Pricing Review</h2>
          <p className="sect-desc">
            Depending on where you live, Xfinity's three packages offer 10–185+
            channels for $20–$80 a month.
          </p>
        </div>
        <InnerPagesHeroTwo data={heroData} />
      </div>
      <StickyHeader />

      <div className="endnote is-xfinity-good">
        <div className="endnote-container">
          <img src={quotationsIcon} alt="quotations" />
          <h4>Is Xfinity TV good?</h4>
          <p>
            Xfinity TV service is good, but it’s not slap-your-mama good*. It
            has up to 185+ live TV channels, the free Xfinity Stream app, the
            well-regarded X1 DVR, and wide availability. But Xfinity’s pricing
            gets expensive when you consider the provider’s relatively low
            channel counts, high DVR/HD fees, and the no-term agreement premium.
            <br />
            (*No mamas were injured in the writing of this piece. Also—hi,
            moms!)
            <br />
            That said, we can see Xfinity working out well for you in a couple
            of scenarios. If you’re sick of your current TV provider, you won’t
            pay too much more for Xfinity—even with some of the higher-priced
            no-term agreement plans. If you also need internet service, Xfinity
            has speedy cable internet connections for bundling.
          </p>
          <br />
          <h4>Xfinity TV pros and cons</h4>
          <div className="custom-table">
            <table>
              <thead>
                <tr>
                  <th>
                    Prospects <IoThumbsUpSharp className="icon" />
                  </th>
                  <th>
                    Consequences <IoThumbsDownSharp className="icon" />
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>Wide availability</td>
                  <td>Smaller channel counts</td>
                </tr>
                <tr>
                  <td>Excellent X1 DVR and voice remote</td>
                  <td>12–24 mo. contracts</td>
                </tr>
                <tr>
                  <td>Xfinity Stream app for mobile viewing</td>
                  <td>33% higher pricing for no-term agreement plans</td>
                </tr>
                <tr>
                  <td> </td>
                  <td>High DVR and equipment fees</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="section important-links">
        <div className="head">
          <h2 className="sect-heading">
            Jump to <span>important links</span>
          </h2>
          <p className="sect-desc">
            Lorem ipsum dolor sit amet consectetur. Sit purus quis porttitor
            scelerisque sem tempor netus. Nisi diam pulvinar pharetra lacus in a
            pellentesque nunc consequat. Commodo elit dignissim vulputate
            cursus. Dictum in morbi vestibulum posuere placerat pellentesque
            eros congue molestie.
          </p>
        </div>

        <div className="all-links">
          <Link to={"#"}>Deals</Link>
          <Link to={"#"}>Plans</Link>
          <Link to={"#"}>competition</Link>
          <Link to={"#"}>specs and features</Link>
          <Link to={"#"}>xfinity tV equipment</Link>
        </div>
      </div>

      <CTA
        heading={"Curious what other TV providers are in your area?"}
        desc={
          "Enter your zip code below to see if you can get Xfinity service."
        }
      />

      <div className="section xfinity-tv-review-package-plans">
        <div className="head">
          <h2 className="sect-heading">
            Xfinity TV <span>packages and plans </span> in your area
          </h2>
          <p className="sect-desc">
            Lorem ipsum dolor sit amet consectetur. Justo egestas auctor arcu
            eget ante nunc condimentum sagittis. Lorem scelerisque facilisis
            faucibus massa mollis sem. Fermentum sed vulputate eget vel sem mi
            tempor. Nunc orci eu lorem enim.
          </p>
        </div>
        <div className="package-table-container channels">
          <h4>Xfinity TV deals and promotions</h4>
          <p className="after-heading">
            In addition to its affordable TV packages and internet-and-TV
            bundles, you can get some of the following Xfinity TV deals and
            promotions:
          </p>
          <br />
          <ul>
            <li>
              · $10/mo. discount for automatic payments and paperless billing
            </li>
            <li>· Up to $30/mo. bundle discount</li>
            <li>· Free premium channels (with select bundles)</li>
          </ul>
          <p className="after-heading">
            For more information, check out our Xfinity deals guide.
          </p>
        </div>
        <div className="package-table-container channels">
          <h4>Xfinity TV plans (West)</h4>
          <PackageTable item={packageTableData} />
          <p className="below-table">
            *No term contract. Broadcast TV fee and regional sports fee extra;
            price subj to change. Taxes and equipment not included.Data
            effective as of publish date. Offers and availability may vary by
            location and are subject to change.
          </p>
        </div>
        <div className="faq-wrapper">
          <div className="accordion">
            <div className="item">
              <h3 className="title" onClick={() => accordionHandler(1)}>
                <span>{faq === 1 ? "-" : "+"}</span> Xfinity TV plans and
                packages (central)
              </h3>

              <div className={`faq-content ${faq === 1 ? "show" : ""}`}>
                <PackageTable item={packageTableData} />
                <p className="below-table">
                  *No term contract. Broadcast TV fee and regional sports fee
                  extra; price subj to change. Taxes and equipment not
                  included.Data effective as of publish date. Offers and
                  availability may vary by location and are subject to change.
                </p>
              </div>
            </div>
          </div>

          <div className="accordion">
            <div className="item">
              <h3 className="title" onClick={() => accordionHandler(2)}>
                <span>{faq === 2 ? "-" : "+"}</span> Xfinity TV plans and
                packages (northeast)
              </h3>

              <div className={`faq-content ${faq === 2 ? "show" : ""}`}>
                <PackageTable item={packageTableData} />
                <p className="below-table">
                  *No term contract. Broadcast TV fee and regional sports fee
                  extra; price subj to change. Taxes and equipment not
                  included.Data effective as of publish date. Offers and
                  availability may vary by location and are subject to change.
                </p>
              </div>
            </div>
          </div>

          <div className="accordion">
            <div className="item">
              <h3 className="title" onClick={() => accordionHandler(3)}>
                <span>{faq === 3 ? "-" : "+"}</span> Which Xfinity region i’m
                in?
              </h3>

              <div className={`faq-content ${faq === 3 ? "show" : ""}`}>
                <div className="custom-table">
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th>West region</th>
                        <th>Northeast region</th>
                        <th>Central region</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>1.</td>
                        <td>Arizona</td>
                        <td>Alabama</td>
                        <td>Connecticut</td>
                      </tr>
                      <tr>
                        <td>2.</td>
                        <td>California</td>
                        <td>Arkansas</td>
                        <td>Delaware</td>
                      </tr>
                      <tr>
                        <td>3.</td>
                        <td>Colorado</td>
                        <td>Florida</td>
                        <td>District Of Columbia</td>
                      </tr>
                      <tr>
                        <td>4.</td>
                        <td>Idaho</td>
                        <td>Georgia</td>
                        <td>Maine</td>
                      </tr>
                      <tr>
                        <td>5.</td>
                        <td>Kansas</td>
                        <td>Illinois</td>
                        <td>Maryland</td>
                      </tr>
                      <tr>
                        <td>6.</td>
                        <td>Minnesota</td>
                        <td>Indiana</td>
                        <td>Massachusetts</td>
                      </tr>
                      <tr>
                        <td>7.</td>
                        <td>Missouri</td>
                        <td>Kentucky</td>
                        <td>New Hampshire</td>
                      </tr>
                      <tr>
                        <td>8.</td>
                        <td>Oregon</td>
                        <td>Michigan</td>
                        <td>New York</td>
                      </tr>
                      <tr>
                        <td>9.</td>
                        <td>Texas</td>
                        <td>Mississippi</td>
                        <td>North Carolina</td>
                      </tr>
                      <tr>
                        <td>10.</td>
                        <td>Utah</td>
                        <td>South Carolina</td>
                        <td>Ohio</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="endnote review-endnote">
          <div className="endnote-container">
            <img className="quotation-img" src={quotations} alt="quotations" />
            <h4>Pro tip: Basic cable plans suck but OTA antennas don’t</h4>
            <p>
              Why pay monthly for local channels when you can buy an
              <span className="link">
                <Link to={"#"}>over-the-air (OTA) antenna?</Link>
              </span>
              For a one-time payment of $25–$70, you’ll get many more channels.
              We like the Mohu Leaf 50. It’s easy to install indoors, and you’ll
              pick up every broadcast channel within 50 miles of your place.
              <br />
              If you still want DVR service to go with your local channels, you
              can get one for $100–$350. We recommend the{" "}
              <span className="link">
                <Link to={"#"}> Tablo Quad 4-Tuner DVR.</Link>
              </span>
            </p>
          </div>
        </div>
      </div>

      <div className="section xfinity-competition">
        <div className="head">
          <h2 className="sect-heading">
            Xfinity TV <span> vs. the competition</span>
          </h2>
          <p className="sect-desc">
            Xfinity’s cable TV plans are average compared to other cable TV
            providers. Xfinity doesn’t have the no-contract flexibility of
            Spectrum, but it’s far more affordable than competitors like Cox.
            <br />
            <br />
            By comparison, satellite TV providers like{" "}
            <span className="link">
              <Link to={"#"}>DISH</Link>
            </span>{" "}
            and{" "}
            <span className="link">
              <Link to={"#"}>DIRECTV</Link>
            </span>
            have far bigger channel counts than Xfinity. But you’ll also be
            paying much more for the privilege, thanks to each provider’s higher
            prices and two-year contracts.
          </p>
        </div>
        <PackageTable item={packageTableData} />
        <p className="below-table">
          Data effective as of publish date. Offers and availability may vary by
          location and are subject to change.
        </p>
        <div className="endnote review-endnote">
          <div className="endnote-container">
            <img className="quotation-img" src={quotations} alt="quotations" />
            <h4>The hidden costs of Xfinity</h4>
            <p>
              Whenever you’re shopping for TV service, it’s smart to ask about
              hidden fees and save the surprises for trashy reality TV shows.
              <br /> In our guide to the{" "}
              <span className="link">
                <Link to={"#"}>hidden costs of Xfinity, </Link>
              </span>{" "}
              we give you the scoop on the various fees—broadcast, DVR, extra
              TV, HD, installation, late, premium channel, reactivation, and
              regional sports network—you might see on your Xfinity bill.
            </p>
          </div>
        </div>
      </div>

      <div className="section xfinity-tv-specs">
        <div className="head">
          <h2 className="sect-heading">
            Xfinity TV <span> specs and features</span>
          </h2>
          <p className="sect-desc">
            Xfinity’s cable plans include a lot more than TV service. From
            on-demand programming to TV boxes, let’s explore everything else
            about Xfinity’s TV plans.
          </p>
        </div>
        <div className="package-table-container channels">
          <h4>Xfinity TV channels</h4>
          <p className="after-heading">
            While Xfinity doesn’t offer as many channels on its cable packages
            as other providers, it carries all of the most popular channels—many
            of them in HD—in its packages, plus a hefty selection of premium
            channels and add-on packs. And, of course, the plans include a few
            dozen Music Choice channels (which makes the channel counts even
            weaker).
            <br />
            Here’s a sampling of Xfinity’s available channels. For a more
            comprehensive look at Xfinity cable TV channels, read our Xfinity
            Channel Lineup guide.
          </p>
          <div className="endnote review-endnote">
            <div className="endnote-container">
              <img
                className="quotation-img"
                src={quotations}
                alt="quotations"
              />
              <h4>Popular channels on Xfinity</h4>
              <br />
              <div className="div-ul">
                <ul>
                  <li>· A&E</li>
                  <li>· Bravo</li>
                  <li>· CNN</li>
                  <li>· Comedy Central</li>
                  <li>· Disney, Disney Junior, and Disney XD</li>
                  <li>· ESPN</li>
                </ul>
                <ul>
                  <li>· FOX News</li>
                  <li>· Lifetime (and LMN)</li>
                  <li>· Nat Geo (and Nat Geo Wild)</li>
                  <li>· Nickelodeon, Nick 2, Nick Jr., and Nicktoons</li>
                  <li>· Syfy</li>
                  <li>· TBS</li>
                </ul>
              </div>
              <div className="btn">
                <Link to={"#"}>get these Latinos channels</Link>
              </div>
            </div>
          </div>
          <div className="endnote review-endnote">
            <div className="endnote-container">
              <img
                className="quotation-img"
                src={quotations}
                alt="quotations"
              />
              <h4>Premium channels on Xfinity</h4>
              <br />
              <div className="div-ul">
                <ul>
                  <li>NEMAX ($12.00/mo.)</li>
                  <li>Deportes ($5.00)</li>
                  <li>EPIX ($5.99/mo.)</li>
                  <li>HBO® Max ($14.99/mo.)</li>
                  <li>NFL RedZone ($9.99/mo.)</li>
                </ul>
                <ul>
                  <li>Playboy TV ($19.99/mo.)</li>
                  <li>SHOWTIME® ($12.00/mo.)</li>
                  <li>STARZ ($8.99 /mo.)</li>
                  <li>STREAMPIX ($4.99/mo.)</li>
                  <li>TMC ($12.00/mo.)</li>
                </ul>
              </div>
              <div className="btn">
                <Link to={"#"}>sign up for Premium channels</Link>
              </div>
            </div>
          </div>
          <div className="endnote review-endnote">
            <div className="endnote-container">
              <img
                className="quotation-img"
                src={quotations}
                alt="quotations"
              />
              <h4>Xfinity add-on packs</h4>
              <br />
              <div className="div-ul">
                <ul>
                  <li>ACORN TV ($5.99/mo.)</li>
                  <li>AMC+ ($4.99/mo.)</li>
                  <li>Anime Network ($6.99/mo.)</li>
                  <li>Curiosity Stream ($2.99/mo.)</li>
                </ul>
                <ul>
                  <li>HERE TV ($7.99/mo.)</li>
                  <li>KidStream ($4.99/mo.)</li>
                  <li>HERE TV ($7.99/mo.)</li>
                  <li>UP Faith & Family ($4.99/mo.)</li>
                </ul>
              </div>
              <div className="btn">
                <Link to={"#"}>sign up for Add-ons</Link>
              </div>
            </div>
            <p className="below-table">
              Data as of post date. Offers and availability may vary by location
              and are subject to change.
            </p>
          </div>
        </div>
        <div className="package-table-container channels">
          <h4>Xfinity bundles in your area</h4>
          <p className="after-heading">
            With Xfinity, you can package your TV plan with internet and phone
            service in double- or triple-play bundles. Again, there are
            different Xfinity bundle packages in each of its three regions.
            We’ve researched prices and compiled pricing comparison tables for
            some popular Xfinity TV and internet bundle deals above.
            <br />
            For more detailed information, read our guide to Xfinity bundles.
          </p>
          <PackageTable item={packageTableData} />
          <p className="below-table">
            *Internet: For 12 months with 1-year term contract. Taxes and
            equipment not included. Includes $10/mo automatic payments and
            paperless billing discount. TV: No term contract. Broadcast TV fee
            extra; price subj to change. Taxes and equipment not included.
            <br />
            Data effective as of publish date. Offers and availability may vary
            by location and are subject to change.
          </p>
        </div>
        <div className="package-table-container channels">
          <h4>Xfinity On Demand</h4>
          <p className="after-heading">
            Available on the X1 and the Xfinity Stream app, Xfinity On Demand
            comes with your TV package and offers a free library with thousands
            of movies, the first latest episodes of the top 100 TV shows, plus
            the ability to rent or purchase titles, including new movies.
            <br />
            Xfinity On Demand adds a whole lotta free content—and, therefore,
            value—to your Xfinity cable packages. And the ability to watch
            Xfinity On Demand content away from home, and even download it to
            watch offline, is a huge bonus.
          </p>
        </div>
        <div className="package-table-container channels">
          <h4>Peacock</h4>
          <p className="after-heading">
            Peacock is a streaming TV service owned by NBCUniversal, which owns
            Xfinity. It has three tiers:
          </p>
          <PackageTable item={packageTableData} />
          <p className="below-table">
            Data as of post date. Offers and availability may vary by location
            and are subject to change.
          </p>
          <p className="after-heading">
            Peacock (with ads) is completely free and has 7,500 hours of
            content—not including Peacock originals. Peacock Premium (with ads)
            is $4.99 a month, but it has twice the content—15,000 hours—with new
            episodes of NBC series available to watch the day after they
            broadcast.
            <br />
            For twice the price, Peacock Premium Plus has the same 15,000 of
            content, but you won’t have to endure commercials. For more
            information on Peacock plans, check out our Peacock Review.
          </p>
        </div>
        <div className="package-table-container channels">
          <h4>Xfinity TV streaming</h4>
          <p className="after-heading">
            Yes, you can stream your Xfinity X1 TV package. Free with your TV
            subscription, Xfinity’s Stream app allows you to watch content and
            manage certain settings from your smartphone, tablet, or laptop:
          </p>
          <ul>
            <li>Up to 200+ live channels (depending on your package)</li>
            <li>Most Xfinity On Demand titles</li>
            <li>All of your DVR recordings</li>
            <li>Set and manage DVR timers</li>
            <li>Set and manage parental controls</li>
          </ul>
          <p className="after-heading">
            Even better, you can watch Xfinity Stream on up to five devices at
            once. That’s a generous number of simultaneous streams, compared to
            other TV providers. DISH, for example, allows only one stream on its
            DISH Anywhere app.
            <br />
            Xfinity Stream also allows you to download titles for offline
            viewing when you’re traveling or in an area with no service.
            <br />
            Read our Xfinity Stream App Guide for more information.
          </p>
          <br />
          <br />
          <img src={tvstream} alt="tvstream" className="tvstream" />
        </div>
      </div>

      <div className="section xfinity-tv-equipment">
        <div className="head">
          <h2 className="sect-heading">
            Xfinity<span> TV equipment</span>
          </h2>
          <p className="sect-desc">
            Ready to talk gear? Xfinity offers the following gadgetry to go with
            your TV package or bundle. Most of them include an additional
            monthly fee.
          </p>
        </div>
        <div className="package-table-container channels">
          <h4>Xfinity X1</h4>
          <p className="after-heading">
            The X1 is Xfinity’s highly rated DVR/receiver and TV service. It
            costs an additional $7.50 to $8.50 per month, depending on your
            region.
            <br />
            But if you don’t need or want the X1’s special features, you won’t
            save money by choosing a standard HD TV box. They’re also between
            $7.50 to $8.50 per month depending on region.
          </p>
          <div class="container">
            <div class="column">
              {" "}
              <span className="link">
                <Link to={"#"}>
                  <img src={xone} alt="cross" className="cross" />
                </Link>
              </span>
            </div>
            <div class="column">
              <span className="link">
                <Link to={"#"}>
                  <img src={xtwo} alt="cross" className="cross" />
                </Link>
              </span>
            </div>
            <div class="column">
              <span className="link">
                <Link to={"#"}>
                  <img src={xthree} alt="cross" className="cross" />
                </Link>
              </span>
            </div>
          </div>
          <p className="below-table">
            Depending where you’re located, and what’s in stock, you’ll get one
            of three Xfinity X1 models.
          </p>
          <p className="after-heading">
            The X1 Cloud DVR service lets you save shows and view them on your
            X1, the Xfinity Stream app, and the Xfinity Stream website. Most
            packages include 20 hours of DVR storage, but you can upgrade to 150
            hours ($10.00/mo.) or 300 hours ($20.00/mo.) of storage during the
            checkout process.
            <br />
            If you’d like to watch your Xfinity content on another TV, you can
            pay $8.50 a month for an extra X1 DVR—or choose a non-DVR X1 TV box
            for $8.50 a month to use on the additional TV. The non-DVR boxes can
            set and play recordings, but not store them (they’ll be on your
            <span className="link">
              <Link to={"#"}> primary X1 DVR).</Link>
            </span>
            <br />
            It’s important to note here that Xfinity sends out various models of
            non-DVR X1 boxes. One, the RNG150N, is not compatible with the
            Xfinity voice remote. If you want that sweet hands-free navigation,
            you might want to ask an Xfinity rep which box you should expect in
            your region.
            <br />
            For more on the X1, read our in-depth Xfinity X1 review.
          </p>
        </div>
        <div className="package-table-container channels">
          <h4>Xfinity Flex</h4>
          <p className="after-heading">
            What is Xfinity Flex? We’re so glad you asked. If you have an
            Xfinity internet plan, but not TV service, and you don’t see a TV
            plan or bundle that fits your budget, consider Xfinity Flex, the
            provider’s streaming device/service.
            <br />
            Flex houses free livestreaming and on-demand apps like Pluto, Tubi,
            Xumo, and YouTube (plus many more). That means you’ll have a
            combined library of 200+ live channels and countless on-demand
            titles (including 10,000+ movies and TV shows from Xfinity alone).
            <br />
            You can also use Flex to watch (or listen to) your HBO, Hulu,
            Netflix, Pandora, Prime Video, and Spotify subscriptions, which is
            supremely convenient.
            <br />
            Unfortunately, the Flex isn’t free for Xfinity customers any longer.
            We’ll keep you updated with details about its new price point when
            we hear more.
            <br />
            <br />
            And to learn more about Flex, read our full Xfinity Flex review.
            <br />
            <br />
          </p>
          <img src={remotereview} alt="" className="" />
        </div>
        <div className="package-table-container channels">
          <h4>Xfinity voice remote</h4>
          <p className="after-heading">
            Both the X1 and Xfinity Flex come with either the Xfinity XR11 or
            XR15 voice remote. The difference between the two is aesthetic, not
            functional—both remotes have these features:
          </p>
          <br />
          <ul>
            <li>· Voice control</li>
            <li>· Backlit keypad</li>
            <li>· 5-minute skip for live TV or DVR recording</li>
            <li>· 10-minute skip for Xfinity On Demand</li>
            <li>· Aim Anywhere function</li>
            <li>· Automatic pairing</li>
            <li>
              · Accessibility features (closed captioning, video description,
              voice guidance)
            </li>
          </ul>
          <p className="after-heading">
            For more information on all Xfinity equipment, see our review.
          </p>
        </div>
      </div>

      <div className="section xfinity-final-take">
        <div className="head">
          <h2 className="sect-heading">
            Final take:<span> Is Xfinity TV worth it?</span>
          </h2>
          <p className="sect-desc">
            Xfinity’s lower channel counts and high DVR and HD fees make it one
            of the pricier TV providers out there. But that X1 DVR is pretty
            powerful, so it’s almost worth the extra expense—unless budgetary
            concerns are top-of-mind for you. But we still think that you’d like
            Xfinity if you’re unhappy with your current provider or if you’re
            looking for both TV and internet service (Xfinity has speeds up to
            3,000 Mbps!).
            <br />
            <br />
            Either way, you’ll still have plenty to watch—and ways to watch
            it—with Xfinity.
          </p>
        </div>
        <div className="all-links">
          <Link to={"#"}>Sign up for xfinity</Link>
        </div>
      </div>

      <div className="section xfinity-tv-review-faq">
        <div className="head">
          <h2 className="sect-heading">
            Xfinity <span>FAQ</span>
          </h2>

          <p className="sect-desc">
            TL;DR? Xfinity kills it. For more detailed info on all Xfinity
            equipment and services, see our comprehensive Xfinity equipment
            guide.
          </p>
        </div>

        <div className="faq-wrapper">
          <div className="accordion">
            <div className="item">
              <h3 className="title" onClick={() => accordionHandler(1)}>
                <span>{faq === 1 ? "-" : "+"}</span> What are Xfinity tV
                packages?
              </h3>

              <div className={`faq-content ${faq === 1 ? "show" : ""}`}>
                <p>
                  Xfinity TV packages by Comcast come through a cable connection
                  with up to 185+ channels. Most packages also include 20 hours
                  of DVR storage via the{" "}
                  <span className="link">
                    <Link to={"#"}>X1 DVR box, </Link>
                  </span>{" "}
                  plus the Xfinity Stream app.
                </p>
                <ul>
                  <li>· Choice TV: $20.00–$25.00/mo., 10+ channels</li>
                  <li>· Popular TV: $50.00–$60.00/mo., 125+ channels</li>
                  <li>· Ultimate TV: $68.50–$80.00/mo., 185+ channels</li>
                </ul>
                <p>
                  Check to see what Xfinity TV packages are available in your
                  area.
                </p>
              </div>
            </div>
          </div>

          <div className="accordion">
            <div className="item">
              <h3 className="title" onClick={() => accordionHandler(2)}>
                <span>{faq === 2 ? "-" : "+"}</span> What is the cheapest
                xfinity tV Package?
              </h3>

              <div className={`faq-content ${faq === 2 ? "show" : ""}`}>
                <p>
                  The cheapest Xfinity internet plan is 75 Mbps for $19.99 in
                  the West region, 75 Mbps for $30.00 in the Central region, and
                  200 Mbps for $25.00/mo. in the Northeast region.
                  Unfortunately, plan pricing and naming varies by region.
                </p>
              </div>
            </div>
          </div>

          <div className="accordion">
            <div className="item">
              <h3 className="title" onClick={() => accordionHandler(3)}>
                <span>{faq === 3 ? "-" : "+"}</span> Can you get xfinity tV
                without cable?
              </h3>

              <div className={`faq-content ${faq === 3 ? "show" : ""}`}>
                <p>
                  Sorry, after April 2023, the{" "}
                  <span className="link">
                    <Link to={"#"}>Xfinity Flex </Link>
                  </span>{" "}
                  won't be free anymore. If you don't mind paying whatever its
                  new price will be, you can request it when you sign up for
                  your Xfinity internet plan (although your customer service rep
                  will probably railroad you into getting it before you get a
                  word in edgewise).
                  <br />
                  However, if you get an{" "}
                  <span className="link">
                    <Link to={"#"}>Xfinity TV plan</Link>
                  </span>
                  , you're not eligible for an Xfinity Flex. That's okay-ish
                  since you'll get the X1 DVR instead. Unfortunately, the X1
                  falls into the non-free category as well due to its $14.95 per
                  month service fee.
                </p>
              </div>
            </div>
          </div>

          <div className="accordion">
            <div className="item">
              <h3 className="title" onClick={() => accordionHandler(4)}>
                <span>{faq === 4 ? "-" : "+"}</span> what is the best package to
                get on xfinity tV?
              </h3>

              <div className={`faq-content ${faq === 4 ? "show" : ""}`}>
                <p>
                  Comcast and Xfinity are the same thing-you can't trip us up
                  with your trick question!
                </p>
                <p>
                  In 2010, Comcast started using Xfinity as its trade name for
                  TV, internet, and phone services.
                  <br />
                  And as part of Comcast, Xfinity has built-in relationships
                  with Comcast's other products, such as NBCUniversal. That's
                  one of the reasons you'll get the{" "}
                  <span className="link">
                    <Link to={"#"}>Peacock Premium</Link>
                  </span>{" "}
                  streaming service for free with your Xfinity cable TV service.
                </p>
              </div>
            </div>
          </div>

          <div className="accordion">
            <div className="item">
              <h3 className="title" onClick={() => accordionHandler(5)}>
                <span>{faq === 5 ? "-" : "+"}</span>can you just get xfinity tV?
              </h3>

              <div className={`faq-content ${faq === 5 ? "show" : ""}`}>
                <p>
                  The best Xfinity deal is Superfast Internet + Ultimate TV,
                  which provides 800 Mbps download speeds and 185+ cable
                  channels. Your savings may vary by region, though, and
                  depending on where you live, it could cost $115-$120 a month.
                </p>
                <p>
                  For more on Xfinity's money-saving double plays, head over to
                  our{" "}
                  <span className="link">
                    <Link to={"#"}>Xfinity bundles review</Link>
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className="accordion">
            <div className="item">
              <h3 className="title" onClick={() => accordionHandler(6)}>
                <span>{faq === 6 ? "-" : "+"}</span>what channels are in the
                xfinity’s basic package?
              </h3>

              <div className={`faq-content ${faq === 6 ? "show" : ""}`}>
                <p>
                  According to our{" "}
                  <span className="link">
                    <Link to={"#"}>customer satisfaction survey,</Link>
                  </span>{" "}
                  62% of Xfinity customers are satisfied with their internet
                  service. In addition, 69% of Xfinity customers are satisfied
                  with their internet speed, tying it for first place with
                  Verizon. Cable service will always be dependent on how heavy
                  usage is in your network, so for more reliable speeds, we
                  recommend{" "}
                  <span className="link">
                    <Link to={"#"}>fiber internet</Link>
                  </span>
                  if you have the option.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="divider">
        <p>
          See also: Xfinity Overview | Xfinity Internet | Xfinity TV | Xfinity
          Voice | Xfinity Bundles | Xfinity Channel Lineup | Xfinity Customer
          Service | Xfinity Availability Map | Xfinity Free Installation
        </p>
      </div>

      <div className="section endnote intertnet-review-endnote">
        <div className="endnote-container">
          <img className="quotation-img" src={quotations} alt="quotations" />
          <h4>Methodology:</h4>
          <p>
            To find out how Xfinity’s TV plans stacked up to the competition, we
            rated them in areas like value, channel count, and customer
            satisfaction. For more information on our methodology, check out our
            .
            <span className="link">
              <Link to={"#"}>How We Rank page</Link>
            </span>
          </p>
        </div>
      </div>
      <Newsletter />
    </div>
  );
};

export default XfinityTvReview;
