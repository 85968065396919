import React from "react";
import "./xfinity-customer-service.scss";
import SubNav from "../../../components/sub-nav/SubNav";
import { xfinityNavLinks } from "../../../assets/data/navLinks/xfinityNavLinks";
import quotationsIcon from "../../../assets/images/quotations.svg";
import CTA from "../../../components/cta-section/CTA";
import { Link } from "react-router-dom";
import whatsapp from "../../../assets/images/whatsapp.png";
import danger from "../../../assets/images/danger.png";
import { GoArrowUpRight } from "react-icons/go";
import texh from "../../../assets/images/texh.png";
import laptopback from "../../../assets/images/laptopback.png";
import phoneback from "../../../assets/images/phoneback.png";
import Newsletter from "../../../components/newsletter/Newsletter";
import quotations from "../../../assets/images/quotations.svg";
import customerone from "../../../assets/images/customerone.png";
import customertwo from "../../../assets/images/customertwo.png";
import customerthree from "../../../assets/images/customerthree.png";
const XfinityCustomerService = () => {
  return ( 
    <div className="xfinity-customer-service">
      <SubNav links={xfinityNavLinks} />
      <div className="xfinity-customer-hero">
        <h1>Xfinity Customer Service</h1>
        <p>Talk to a real-life person about your Xfinity service today.</p>
        <div class="container-img">
          <div class="column-img">
            <img src={customerthree} alt="customer" className="" />
          </div>
          <div class="column-img">
            <img src={customertwo} alt="customer" className="" />
          </div>
          <div class="column-img">
            <img src={customerone} alt="customer" className="" />
          </div>
        </div>
      </div> 

      <CTA
        heading={"Curious what other providers are in your area?"}
        desc={
          "Enter your zip code below to find the right internet and TV service for you."
        }
      />
      <div className="section endnote endnote-start">
        <div className="endnote-container">
          <img src={quotationsIcon} alt="quotations" />
          <p>
            On{" "}
            <span className="link">
              <Link to={"#"}> Xfinity’s website,</Link>
            </span>
            you can chat with live sales agents or use the shopping tool to
            learn more about Xfinity internet, phone, and cable TV packages. The
            Xfinity sales page lets you bundle services and compare factors like
            download speeds and available channels.
            <br />
            If you’re interested in subscribing to Xfinity, click the buttons
            above to speak to an Xfinity customer service representative.
          </p>
          <br />
        </div>
      </div>

      <div className="section important-links">
        <div className="head">
          <h2 className="sect-heading">
            Current customers: <span> I’m looking for</span>
          </h2>
          <p className="sect-desc">
            Lorem ipsum dolor sit amet consectetur. Sit purus quis porttitor
            scelerisque sem tempor netus. Nisi diam pulvinar pharetra lacus in a
            pellentesque nunc consequat. Commodo elit dignissim vulputate
            cursus. Dictum in morbi vestibulum posuere placerat pellentesque
            eros congue molestie.
          </p>
        </div>

        <div className="all-links">
          <Link to={"#"}>Over-the-phone support</Link>
          <Link to={"#"}>Xfinity customer service hours</Link>
          <Link to={"#"}>Xfinity outages </Link>
          <Link to={"#"}> Online support</Link>
          <Link to={"#"}>Tech support </Link>
          <Link to={"#"}>Business support </Link>
          <Link to={"#"}> Local support</Link>
          <Link to={"#"}> Xfinity bill pay</Link>
          <Link to={"#"}> Xfinity service cancellation</Link>
        </div>
        <div className=" endnote endnote-start">
          <div className="endnote-container">
            <img src={danger} alt="danger" />
            <h4>Before you call Xfinity</h4>
            <p>
              Xfinity’s chat and call scheduling services eliminate many of the
              problems you’ll have during a typical support call, but they’ll
              still need to verify your identity via phone or chat.
              <br />
              To save time during your call, follow these tips:
            </p>
            <ul>
              <li>
                Make sure you have your name, account number, and address ready.
                Your account number is on top of your PDF or paper bill.
              </li>
              <li>
                Make sure your callback number is the same as the phone number
                registered to your Xfinity account. This makes it faster for
                Xfinity agents to pull up your account and answer your
                questions.
              </li>
              <li>
                If you’re planning a move, have your new address and service
                transfer dates nearby.
              </li>
              <li>
                Keep a pencil and notepad nearby to jot down any notes, costs,
                or dates related to your billing issues.
              </li>
            </ul>
            <p>
              <i>
                {" "}
                If you don’t have your account number on hand, Xfinity can still
                verify your account with billing or personal account details.
                Xfinity can also send a verification code to your registered
                phone number or email address.
              </i>
            </p>
            <br />
          </div>
        </div>
      </div>

      <div className="section customer-phone-number">
        <div className="head">
          <h2 className="sect-heading">
            Xfinity <span>customer service</span>phone number
          </h2>
          <p className="sect-desc">
            <span className="link">
              <Link to={"#"}> Xfinity’s callback service </Link>
            </span>
            lets you schedule a time to speak to a Xfinity customer service
            agent. With the service, which handles Xfinity internet and billing
            department questions, you don’t have to spend hours on hold waiting
            for an agent. So before your scheduled phone call, you can use the
            time to prep some meals for the week, catch up on your TV show
            backlog, mow the lawn, or take a walk.
            <br />
            The customer service representative will also have your account
            pulled up to save time during the call. The callback service is
            available on the Xfinity website and Xfinity My Account mobile app.
          </p>
        </div>
        <div className="call-back">
          <div className="call-back-container">
            <div className="icon-box">
              <img src={whatsapp} alt="whatsapp" className="whatsapp" />
              <div className="icon-box-content">
                <h4>customer service phone number</h4>
                <br />
                <p>Between 8:00 a.m. and 9:00 p.m. ET Monday through Sunday.</p>
              </div>
            </div>
            <hr />
          </div>
          <Link to={"#"}>
            <p>schedule a callback</p>
            <div className="arrow-icon-box">
              <GoArrowUpRight className="upright-arrow-icon" />
            </div>
          </Link>
        </div>
      </div>

      <div className="section service-hours">
        <div className="head">
          <h2 className="sect-heading">
            Xfinity customer service <span> hours of operation</span>
          </h2>
          <p className="sect-desc">
            Xfinity’s live hours for its phone and chat agents are between 8:00
            a.m. and 9:00 p.m. ET Monday through Sunday.
            <br />
            If you need assistance outside of business hours, Xfinity Assistant
            is a 24/7 automated chatbot that can assist with common account,
            technical, or billing questions.
          </p>
        </div>
        <div className="call-back">
          <div className="call-back-container">
            <div className="icon-box">
              <img src={whatsapp} alt="whatsapp" className="whatsapp" />
              <div className="icon-box-content">
                <h4>customer service phone number</h4>
                <br />
                <p>Between 8:00 a.m. and 9:00 p.m. ET Monday through Sunday.</p>
              </div>
            </div>
            <hr />
          </div>
          <Link to={"#"}>
            <p>schedule a callback</p>
            <div className="arrow-icon-box">
              <GoArrowUpRight className="upright-arrow-icon" />
            </div>
          </Link>
        </div>
      </div>

      <div className="section customer-outages">
        <div className="head">
          <h2 className="sect-heading">
            Xfinity <span> outages </span>
          </h2>
          <p className="sect-desc">
            The Xfinity Outage Map is located on{" "}
            <span className="link">
              <Link to={"#"}>Xfinity’s Status Center </Link>
            </span>{" "}
            webpage and the Xfinity My Account mobile app, available on{" "}
            <span className="link">
              <Link to={"#"}>Google Play </Link>
            </span>{" "}
            or the{" "}
            <span className="link">
              <Link to={"#"}>App Store </Link>
            </span>
            . The map shows outage locations and estimates the number of
            affected subscribers.
            <br />
            You can also tweet at @t{" "}
            <span className="link">
              <Link to={"#"}> XfinitySuppor </Link>
            </span>
            , Xfinity’s live support account on Twitter, or text OUT to 266278
            to check for outages. To receive text alerts, your phone needs to be
            registered through your Xfinity account. Check out our{" "}
            <span className="link">
              <Link to={"#"}>Xfinity outage </Link>
            </span>
            guide for your full list of options.
          </p>
        </div>
        <div className="call-back">
          <div className="call-back-container">
            <div className="icon-box">
              <img src={whatsapp} alt="whatsapp" className="whatsapp" />
              <div className="icon-box-content">
                <h4>customer service phone number</h4>
                <br />
                <p>Between 8:00 a.m. and 9:00 p.m. ET Monday through Sunday.</p>
              </div>
            </div>
            <hr />
          </div>
          <Link to={"#"}>
            <p>schedule a callback</p>
            <div className="arrow-icon-box">
              <GoArrowUpRight className="upright-arrow-icon" />
            </div>
          </Link>
        </div>
      </div>

      <div className="section service-online">
        <div className="head">
          <h2 className="sect-heading">
            Xfinity <span> customer service </span> online
          </h2>
          <p className="sect-desc">
            The Xfinity Assistant chatbot can answer basic questions and
            redirect you to guides for simple tasks like changing your billing
            address or setting up your Xfinity cable service. For more complex
            problems, you can also request to receive a phone call or use
            Xfinity Assistant to connect with a live customer service agent.
            <br />
            If you’re troubleshooting problems outside of Xfinity’s live
            customer support hours or want to solve them yourself,{" "}
            <span className="link">
              <Link to={"#"}>Xfinity’s website hosts a support section</Link>
            </span>{" "}
            that features numerous help guides. These FAQs cover subjects
            including troubleshooting Wi-Fi issues, ways to pay your bill, and
            installing Xfinity hardware.
          </p>
        </div>
        <div className="call-back">
          <div className="call-back-container">
            <div className="icon-box">
              <img src={whatsapp} alt="whatsapp" className="whatsapp" />
              <div className="icon-box-content">
                <h4>customer service phone number</h4>
                <br />
                <p>Between 8:00 a.m. and 9:00 p.m. ET Monday through Sunday.</p>
              </div>
            </div>
            <hr />
          </div>
          <Link to={"#"}>
            <p>schedule a callback</p>
            <div className="arrow-icon-box">
              <GoArrowUpRight className="upright-arrow-icon" />
            </div>
          </Link>
        </div>
      </div>

      <div className="section important-links online-support">
        <div className="head">
          <h2 className="sect-heading">
            Other Xfinity <span>online support</span>
          </h2>
          <p className="sect-desc">
            Lorem ipsum dolor sit amet consectetur. Sit purus quis porttitor
            scelerisque sem tempor netus. Nisi diam pulvinar pharetra lacus in a
            pellentesque nunc consequat. Commodo elit dignissim vulputate
            cursus. Dictum in morbi vestibulum posuere placerat pellentesque
            eros congue molestie.
          </p>
        </div>

        <div className="all-links">
          <Link to={"#"}>Understanding Your Bill</Link>
          <Link to={"#"}>Ways to Sign In to Xfinity My Account</Link>
          <Link to={"#"}>Bill Payment Options Overview</Link>
          <Link to={"#"}>Perform A Speed Test</Link>
          <Link to={"#"}>X1 Remote Setup </Link>
          <Link to={"#"}>Business support </Link>
          <Link to={"#"}> Overview of Xfinity Gateways</Link>
        </div>
      </div>

      <div className="section tech-support">
        <div className="head">
          <h2 className="sect-heading">
            Xfinity <span> tech support</span>
          </h2>
          <p className="sect-desc">
            If you need to troubleshoot services like your Xfinity Wi-Fi
            connection, you can turn to Xfinity’s phone callback service or live
            chat to speak to an agent.
            <br />
            The support guide section on Xfinity’s website also has articles
            that walk you through the installation process and show features for
            your Xfinity phone, cable, or internet service.
          </p>
        </div>
        <div className="call-back">
          <div className="call-back-container">
            <div className="icon-box">
              <img src={texh} alt="whatsapp" className="whatsapp" />
              <div className="icon-box-content">
                <h4>Xfinity tech support</h4>
                <br />
                <p>Between 8:00 a.m. and 9:00 p.m. ET Monday through Sunday.</p>
              </div>
            </div>
            <hr />
          </div>
          <Link to={"#"}>
            <p>schedule a callback</p>
            <div className="arrow-icon-box">
              <GoArrowUpRight className="upright-arrow-icon" />
            </div>
          </Link>
        </div>
        <div className="endnote endnote-start">
          <div className="endnote-container">
            <img src={quotationsIcon} alt="quotations" />
            <h4>Related articles</h4>
            <br />
            <ul>
              <li>
                ·{" "}
                <span className="link">
                  <Link to={"#"}>Xfinity Stream App Guide </Link>
                </span>
              </li>
              <li>
                ·{" "}
                <span className="link">
                  <Link to={"#"}> Xfinity Installation Guide</Link>
                </span>
              </li>
              <li>
                ·{" "}
                <span className="link">
                  <Link to={"#"}>How to Change or Cancel Xfinity Service </Link>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="section support-business">
        <div className="head">
          <h2 className="sect-heading">
            Xfinity customer support for <span> your business </span>
          </h2>
          <p className="sect-desc">
            Comcast Business customers can call Xfinity at 1-800-391-3000 for
            24/7 assistance. Subscribers can also contact Comcast Business chat
            agents for additional support. While exact chat hours vary depending
            on where you’re located, Comcast Business generally maintains
            regular business hours between 9 a.m. to 5 p.m.
            <br />
            If you need chat help outside of this window, Comcast Business also
            has an after-hours team that records your case and has a
            representative reach out the following business day. If you need to
            immediately solve your troubleshooting problem, the{" "}
            <span className="link">
              <Link to={"#"}> Comcast Business support web page </Link>
            </span>
            features articles that focus on business-specific internet, TV, and
            phone questions.
          </p>
        </div>
        <div className="call-back">
          <div className="call-back-container">
            <div className="icon-box">
              <img src={whatsapp} alt="whatsapp" className="whatsapp" />
              <div className="icon-box-content">
                <h4>customer service phone number</h4>
                <br />
                <p>Between 8:00 a.m. and 9:00 p.m. ET Monday through Sunday.</p>
              </div>
            </div>
            <hr />
          </div>
          <Link to={"#"}>
            <p>schedule a callback</p>
            <div className="arrow-icon-box">
              <GoArrowUpRight className="upright-arrow-icon" />
            </div>
          </Link>
        </div>
      </div>

      <div className="section tech-support">
        <div className="head">
          <h2 className="sect-heading">
            Xfinity <span> local customer service </span> options
          </h2>
          <p className="sect-desc">
            At local Xfinity retail stores, you can manage your account,
            exchange equipment, and learn more about Xfinity services in-person.
            Use the Xfinity retail store locator to find the nearest Xfinity
            store or service center.
          </p>
        </div>
        <div className="endnote endnote-start">
          <div className="endnote-container">
            <h4>Pay your Xfinity bill</h4>
            <br />
            <ul>
              <li>
                · To pay online, go to your Xfinity account page and click Make
                a Payment under the Billing tab.
              </li>
              <li>
                · You can also make online payments through the Xfinity
                Assistant chatbot.
              </li>
              <li>
                · If you want to pay by phone, call 1-800-934-6489 and press 2
                to reach Xfinity’s automated payment system. Make sure your
                account number is nearby.
              </li>
              <li>
                · In the Xfinity My Account app, available on Google Play or the
                App Store, click Billing on the home page and press Pay Now.
              </li>
              <li>
                · To pay via mail, send a check using the insert included in
                your Xfinity paper bill. You can also pay bills in-person at a
                local Xfinity store.
              </li>
            </ul>
            <div className="two-callbacks">
              <div className="call-back">
                <div className="call-back-container">
                  <div className="icon-box">
                    <img src={laptopback} alt="whatsapp" className="whatsapp" />
                    <div className="icon-box-content">
                      <h4>Pay your Xfinity bill</h4>
                      <br />
                      <p>
                        call 1-800-934-6489 and press 2 to reach Xfinity’s
                        automated payment system
                      </p>
                    </div>
                  </div>
                  <hr />
                </div>
                <Link to={"#"}>
                  <p>schedule a callback</p>
                  <div className="arrow-icon-box">
                    <GoArrowUpRight className="upright-arrow-icon" />
                  </div>
                </Link>
              </div>
              <div className="call-back">
                <div className="call-back-container">
                  <div className="icon-box">
                    <img src={phoneback} alt="whatsapp" className="whatsapp" />
                    <div className="icon-box-content">
                      <h4>Pay your Xfinity bill</h4>
                      <br />
                      <p>
                        online payments through the Xfinity Assistant chatbot.
                      </p>
                    </div>
                  </div>
                  <hr />
                </div>
                <Link to={"#"}>
                  <p>schedule a callback</p>
                  <div className="arrow-icon-box">
                    <GoArrowUpRight className="upright-arrow-icon" />
                  </div>
                </Link>
              </div>
            </div> 
          </div>
        </div>
      </div>

      <div className="section suspend-xfinity-service">
        <div className="head">
          <h2 className="sect-heading">
            <span> Cancel, change, or suspend your </span> Xfinity service
          </h2>
          <p className="sect-desc">
            Lorem ipsum dolor sit amet consectetur. Vitae varius volutpat
            habitant pharetra vulputate ipsum non. Sit lectus laoreet velit
            convallis lectus tincidunt ipsum. Pellentesque enim orci nunc nisi
            eu pretium gravida. Nulla dui sit ut nibh egestas suspendisse. At
            purus massa tellus sit feugiat morbi. Purus at dolor.
          </p>
        </div>

        <div className="endnote endnote-start">
          <div className="endnote-container">
            <h4>Changing Xfinity services</h4>
            <br />
            <p>
              To change your{" "}
              <span className="link">
                <Link to={"#"}>Xfinity package, </Link>
              </span>
              schedule an Xfinity{" "}
              <span className="link">
                <Link to={"#"}>phone callback </Link>
              </span>
              or speak to a live{" "}
              <span className="link">
                <Link to={"#"}> Xfinity chat</Link>
              </span>
              agent. You can also add premium TV channels and cancel Xfinity
              Advanced Security or Instant TV services through the Xfinity
              website.
            </p>
            <div className="call-back">
              <div className="call-back-container">
                <div className="icon-box">
                  <img src={phoneback} alt="whatsapp" className="whatsapp" />
                  <div className="icon-box-content">
                    <h4>Xfinity tech support</h4>
                    <br />
                    <p>
                      Between 8:00 a.m. and 9:00 p.m. ET Monday through Sunday.
                    </p>
                  </div>
                </div>
                <hr />
              </div>
              <Link to={"#"}>
                <p>schedule a callback</p>
                <div className="arrow-icon-box">
                  <GoArrowUpRight className="upright-arrow-icon" />
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="endnote endnote-start">
          <div className="endnote-container">
            <h4>Pausing Xfinity services</h4>
            <br />
            <p>
              Visit{" "}
              <span className="link">
                <Link to={"#"}>ComcastSeasonal.com </Link>
              </span>
              or mention seasonal plans to an Xfinity customer support agent to
              suspend your Xfinity service. Depending on your subscriptions,
              you’ll pay a discounted rate over the suspension period.
            </p>
            <div className="call-back">
              <div className="call-back-container">
                <div className="icon-box">
                  <img src={phoneback} alt="whatsapp" className="whatsapp" />
                  <div className="icon-box-content">
                    <h4>Xfinity tech support</h4>
                    <br />
                    <p>
                      Between 8:00 a.m. and 9:00 p.m. ET Monday through Sunday.
                    </p>
                  </div>
                </div>
                <hr />
              </div>
              <Link to={"#"}>
                <p>schedule a callback</p>
                <div className="arrow-icon-box">
                  <GoArrowUpRight className="upright-arrow-icon" />
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="endnote endnote-start">
          <div className="endnote-container">
            <h4>Canceling Xfinity services</h4>
            <br />
            <p>
              To cancel your Xfinity services, schedule a{" "}
              <span className="link">
                <Link to={"#"}>phone callback, </Link>
              </span>
              connect to a{" "}
              <span className="link">
                <Link to={"#"}>chat agent, </Link>
              </span>{" "}
              or visit your{" "}
              <span className="link">
                <Link to={"#"}>local Xfinity store. </Link>
              </span>
              You can also complete an{" "}
              <span className="link">
                <Link to={"#"}>online cancellation form </Link>
              </span>
              or mail a form that includes your full name, service address,
              account number, and phone number to:
            </p>
            <ul>
              <li>Comcast Cable</li>
              <li> ATTN: Service Change Requests</li>
              <li> 1701 JFK Blvd.</li>
              <li> Philadelphia, PA 19103</li>
            </ul>
            <p>
              Xfinity will confirm mail and email requests within two business
              days.
            </p>
          </div>
        </div>
      </div>

      <div className="divider">
        <p>
          See also: Xfinity Overview | Xfinity Internet | Xfinity TV | Xfinity
          Voice | Xfinity Bundles | Xfinity Channel Lineup | Xfinity Customer
          Service | Xfinity Availability Map | Xfinity Free Installation
        </p>
      </div>

      <div className="section endnote intertnet-review-endnote">
        <div className="endnote-container">
          <img className="quotation-img" src={quotations} alt="quotations" />
          <h4>Methodology:</h4>
          <p>
            Our experts poured over Xfinity cable TV and internet pricing
            information for multiple regions to uncover hard-to-find fees. For
            more information on our methodology, check out our{" "}
            <span className="link">
              <Link to={"#"}>How We Rank </Link>
            </span>{" "}
            page.
          </p>
        </div>
      </div>
      <Newsletter />
    </div>
  );
};

export default XfinityCustomerService;
