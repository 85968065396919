import React, { useState } from "react";
import "./xfinity-hidden-costs.scss";
import SubNav from "../../../components/sub-nav/SubNav";
import { xfinityNavLinks } from "../../../assets/data/navLinks/xfinityNavLinks";
import { PackageCard } from "../../../components/package-card/PackageCard";
import { bestOffersTemporary } from "../../../assets/data/bestOffersTemporary";
import quotationsIcon from "../../../assets/images/quotations.svg";
import StickyHeader from "../../../components/sticky-header/StickyHeader";
import { Link } from "react-router-dom";
import Newsletter from "../../../components/newsletter/Newsletter";
import quotations from "../../../assets/images/quotations.svg";

const XfinityHiddenCosts = () => {
  const [faq, setFaq] = useState();

  let accordionHandler = (i) => {
    if (faq === i) {
      return setFaq(null);
    }

    setFaq(i);
  };
  return (
    <div className="xfinity-hidden-costs">
      <SubNav links={xfinityNavLinks} />
      <div className="xfinity-hidden-costs-hero">
        <h1>
          Hidden Costs Of Xfinity: Fees, Installation Costs, And Price Increases
        </h1>
        <p>
          Year-end price hikes, equipment fees, and data overages: get the
          skinny on all these bloated Xfinity fees and more so you’re not
          surprised by your next bill.
        </p>
      </div>

      <div className="section best-internet-tv-offer">
        <div className="cards">
          {bestOffersTemporary.map((item, index) => (
            <PackageCard
              key={index}
              img={item.img}
              tagName={item.tagName}
              packageName={item.packageName}
              packagePoints={item.packagePoints}
              price={item.price}
              linkPath={item.linkPath}
            />
          ))}
        </div>
      </div>

      <div className="section endnote endnote-start">
        <div className="endnote-container">
          <img src={quotationsIcon} alt="quotations" />
          <p>
            There are costs involved in bringing you Xfinity’s hundreds of
            channels and thousands of on-demand titles, but the only price most
            customers consider is the advertised package price—unless they
            happen to read the fine print. As a result, the hidden costs can be
            quite a shock when you get that first bill.
            <br />
            Xfinity fees aren’t too bad, but they can add up if you’re not
            careful. To help you avoid any surprises on your upcoming Xfinity
            bill, we’ll explain each hidden fee and why you might see these
            additional charges.
          </p>
          <br />
        </div>
      </div>
 
      <StickyHeader />

      <div className="section important-links">
        <div className="head">
          <h2 className="sect-heading">
            Jump to <span>important links</span>
          </h2>
          <p className="sect-desc">
            Lorem ipsum dolor sit amet consectetur. Sit purus quis porttitor
            scelerisque sem tempor netus. Nisi diam pulvinar pharetra lacus in a
            pellentesque nunc consequat. Commodo elit dignissim vulputate
            cursus. Dictum in morbi vestibulum posuere placerat pellentesque
            eros congue molestie.
          </p>
        </div>

        <div className="all-links">
          <Link to={"#"}>hidden cost of xfinity tV plans</Link>
          <Link to={"#"}>hidden costs of xfinity internet plans</Link>
        </div>
      </div>

      <div className="section xfinity-hidden-costs-specs-feature">
        <div className="head">
          <h2 className="sect-heading">
            Hidden costs <span> of Xfinity TV plans</span>
          </h2>
          <p className="sect-desc">
            Lorem ipsum dolor sit amet consectetur. Justo egestas auctor arcu
            eget ante nunc condimentum sagittis. Lorem scelerisque facilisis
            faucibus massa mollis sem. Fermentum sed vulputate eget vel sem mi
            tempor. Nunc orci eu lorem enim.
          </p>
        </div>
        <div className="package-table-container channels">
          <h4>Hidden costs of Xfinity TV plans</h4>
          <p className="after-heading">
            If you love bundling, why stop at Xfinity TV, internet, and home
            phone when you can have Xfinity cell phone service too?
            <br />
            Xfinity Mobile is only for Xfinity internet customers, and it offers
            a couple of middle-of-the-road cell phone plans—nothing too flashy.
            You can also get excellent bundle deals when you combine Xfinity
            Mobile with an Xfinity internet plan and an Xfinity Flex. Check out
            our{" "}
            <span className="link">
              <Link to={"#"}> Xfinity Mobile</Link>
            </span>{" "}
            review to learn more about the service.
          </p>
          <div className="custom-table">
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>Fees</th>
                  <th>Amount</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>1 </td>
                  <td>Broadcast TV fee</td>
                  <td>Up to $26.85/mo.</td>
                </tr>
                <tr>
                  <td>2 </td>
                  <td>Regional sports fee</td>
                  <td>Up to $14.45/mo.</td>
                </tr>
                <tr>
                  <td>3 </td>
                  <td>Professional installation</td>
                  <td>$100.00.</td>
                </tr>
                <tr>
                  <td>4 </td>
                  <td>Self-installation kit</td>
                  <td>Up to $39.99</td>
                </tr>
                <tr>
                  <td>5 </td>
                  <td>Reactivation fee</td>
                  <td>$6.00</td>
                </tr>
                <tr>
                  <td>6 </td>
                  <td>DVR service fee</td>
                  <td>150 hours for $10.00/mo.</td>
                </tr>
                <tr>
                  <td>7 </td>
                  <td>HD technology fee</td>
                  <td>$9.95/mo.</td>
                </tr>
                <tr>
                  <td>8 </td>
                  <td>Additional TV service per TV</td>
                  <td>$8.50/mo. ea.</td>
                </tr>
                <tr>
                  <td>9 </td>
                  <td>Max® subscription</td>
                  <td>$15.99/mo.</td>
                </tr>
                <tr>
                  <td>10 </td>
                  <td>SHOWTIME® subscription</td>
                  <td>$12.00/mo.</td>
                </tr>
                <tr>
                  <td>11 </td>
                  <td>CINEMAX® subscription</td>
                  <td>$12.00/mo.</td>
                </tr>
                <tr>
                  <td>12 </td>
                  <td> STARZ® subscription</td>
                  <td>$8.99/mo.</td>
                </tr>
                <tr>
                  <td>13 </td>
                  <td>Late payment fee</td>
                  <td>$10.00</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="package-table-container channels">
          <h4>Xfinity TV promotional cost: $50.00–$80.00/mo.*</h4>
          <p className="after-heading">
            Like most providers, Xfinity offers limited-time promotional pricing
            when you sign up for a new package. Prices change occasionally and
            vary by region, and you can head over to our{" "}
            <span className="link">
              <Link to={"#"}> Xfinity TV review </Link>
            </span>{" "}
            for a full list, but you’ll probably be looking at something like
            this:
          </p>
          <br />
          <ul>
            <li>· Popular TV: $50.00–$60.00/mo.* for 125+ channels</li>
            <li>· Ultimate TV: $68.50–$80.00/mo.* for 185+ channels</li>
          </ul>
          <p className="below-table">
            *Price varies by area. For the first 12 months with no term
            agreement.
          </p>
          <p className="after-heading">
            But, like the asterisk says, Xfinity prices are only guaranteed for
            the first 12 months. Then Xfinity will switch you over to what it
            calls its “regular rate.”
          </p>
        </div>
        <div className="package-table-container channels">
          <h4>
            Xfinity price increase after promo period: up to an additional
            $30/mo.
          </h4>
          <p className="after-heading">
            The increased amount varies depending on your package and where you
            live, but it will usually fall between $20 to $30 per month. The
            specific increase for each plan can be found in the offer terms when
            you sign up—Xfinity calls it the “regular rate.”
            <br />
            You may not be doomed to pay Xfinity’s new price after 12 months,
            though. This writer had luck contacting Xfinity about a price
            increase and saved cash with a new yearlong contract. It’s worth
            reaching out to Xfinity with our{" "}
            <span className="link">
              <Link to={"#"}>customer service guide </Link>
            </span>{" "}
            to see if you can get a new deal after the promo period ends.
          </p>
        </div>
        <div className="package-table-container channels">
          <h4>Taxes, regulatory fees, and more: vary by area and package</h4>
          <p className="after-heading">
            One hidden cost that’s usually mentioned only in the fine print is
            taxes. Exactly how much you’ll be on the hook for depends on the
            plan you choose and where you live. It’s not usually a hefty fee,
            but it’s annoying to see on your bill if you weren’t expecting it.
            <br />
            Regulatory fees are another couple-dollar fee you’re likely to see
            at the bottom of your bill. These stem from federally mandated fees
            on the provider, and to help recoup the costs, Xfinity passes a
            portion on to you.
            <br />
            Xfinity, sometimes sharing is not caring.
            <br />
            You may also have to pay various service fees associated with
            different programming packages. Depending on the channels and
            services you order, you may see charges each month for{" "}
            <span className="link">
              <Link to={"#"}>broadcast TV fees, regional sports network </Link>
            </span>{" "}
            fees, and more.
          </p>
        </div>
        <div className="package-table-container channels">
          <h4>Xfinity installation fees: $100.00 professional installation</h4>
          <p className="after-heading">
            Xfinity typically charges an $100.00 installation fee for a
            professional installation but it’s paused in-person installations in
            some markets due to COVID-19.
            <br />
            Xfinity also offers a self-installation kit for $15.00. If you want
            priority shipping, you’ll have to pay $29.95.
            <br />
            You can also pay $39.99 for Self-Install Plus, which will have an
            Xfinity technician come by to check your home’s exterior connection
            and coordinate your self-installation. Lastly, if you turn your
            service off for any reason, Xfinity charges a $6.00 reactivation fee
            to turn it back on. Learn more about Xfinity installation by
            visiting our
            <span className="link">
              <Link to={"#"}>installation guide. </Link>
            </span>
          </p>
        </div>
        <div className="endnote endnote-start">
          <div className="endnote-container">
            <img src={quotationsIcon} alt="quotations" />
            <h4>Cheapest Xfinity package we recommend</h4>
            <p>
              To get the best bang for your buck with Xfinity TV, we recommend
              the Popular TV package. This TV-only package gets you 140+
              channels for $50.00 or $60.00 per month for 12 months, depending
              on your area.
            </p>
            <br />
            <div className="btn">
              <Link to={"#"}>view xfinity tv plans</Link>
            </div>
          </div>
        </div>
        <div className="package-table-container channels">
          <h4>Xfinity equipment fees: $17.45/mo. for HD DVR</h4>
          <p className="after-heading">
            Xfinity’s charges $8.50 monthly for its{" "}
            <span className="link">
              <Link to={"#"}>X1 DVR system. </Link>
            </span>{" "}
            Plus, it tacks on a mandatory $9.95 per month HD technology fee and
            an optional $10.00 per month for 150 hours of storage.
            <br />
            Of course, you could just go with the included TV box, which comes
            at no extra charge. But you won’t be able to record your shows, or
            even watch in HD—which affects the visual quality of your favorite
            shows. And if you want to add TV service to other rooms, that’s
            another $8.50 per month, per TV. Outfitting a home with TV service
            when you have lots of televisions can end up costing you a bunch.
            <br />
            Check out our{" "}
            <span className="link">
              <Link to={"#"}> Xfinity equipment review</Link>
            </span>{" "}
            to learn more about these devices.
          </p>
        </div>
        <div className="package-table-container channels">
          <h4>Premium channel fees: Max for $15.99/mo.</h4>
          <p className="after-heading">
            Xfinity allows you to add premium channels and specialty channels to
            your cable package of choice for an additional fee. Keep in mind
            that these fees are in addition to your regular cable package and
            that certain Xfinity TV plans include some of these channels.
          </p>
          <ul>
            <li>· Max (formerly HBO Max): $15.99/mo.</li>
            <li>· CINEMAX: $12.00/mo.</li>
            <li>· SHOWTIME: $12.00/mo.</li>
            <li>· STARZ: $8.99/mo.</li>
            <li>· MGM+™: $5.99/mo.</li>
            <li>· THE MOVIE CHANNEL (TMC): $12.00/mo.</li>
          </ul>
          <p className="after-heading">
            Wondering which of these are actually worth the extra cash? Our{" "}
            <span className="link">
              <Link to={"#"}>2022 streaming </Link>
            </span>
            review has the run-down.
          </p>
        </div>
        <div className="package-table-container channels">
          <h4>Late payment fee: $10 per missed payment</h4>
          <p className="after-heading">
            If you don’t pay your Xfinity bill on time, the company charges a
            $10 fee. As part of Xfinity’s late fee policy, there is a grace
            period of up to two weeks before you’ll see any interruption of
            service, but the late fee goes into effect immediately. If you’re in
            a pinch, it’s worth calling in (find out how with our{" "}
            <span className="link">
              <Link to={"#"}>Xfinity customer service guide </Link>
            </span>
            ) to see if you can get an extended grace period.
          </p>
        </div>
        <div className="package-table-container channels">
          <h4>
            Cancellation and early termination fees: may vary by area and plan
          </h4>
          <p className="after-heading">
            If you are looking to cancel, move, transfer, or pause Xfinity
            service we have all the details you’ll need with our{" "}
            <span className="link">
              <Link to={"#"}>Xfinity cancellation guide. </Link>
            </span>{" "}
            The truth is, Xfinity is very vague about cancellation and early
            termination fees (ETF), and you’ll need to call Xfinity to find out
            what yours will be. Xfinity offers both contract and no-contract
            packages, so not every customer will be subject to ETFs.
            <br />
            If you have a contract or service agreement and cancel it early,
            you’ll be required to pay an early termination fee. The exact amount
            of the ETF varies based on how much of your contract remains, with
            the cost decreasing a set amount each month.
            <br />
            You can avoid ETFs by going with a no-contract—or no-term
            agreement—package. But because those are about $20 more per month
            than contracted plans, there’s still a cost here. Talk with your
            Xfinity agent to decide what deal will work best for your situation.
          </p>
        </div>
      </div>

      <div className="section xfinity-hidden-costs-internet-plans">
        <div className="head">
          <h2 className="sect-heading">
            <span> Hidden costs</span> of Xfinity internet plans
          </h2>
          <p className="sect-desc">
            Lorem ipsum dolor sit amet consectetur. Nunc sollicitudin sed magna
            ac. Tristique id turpis amet sem vitae faucibus auctor in faucibus.
            Non id non mi sed. Mattis consectetur euismod ac libero viverra
            neque dictum laoreet non.
          </p>
        </div>
        <div className="package-table-container channels">
          <h4>Hidden costs of Xfinity internet plans</h4>
          <div className="custom-table">
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>Fees</th>
                  <th>Amount</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>1 </td>
                  <td>Professional installation</td>
                  <td>$100.00</td>
                </tr>
                <tr>
                  <td>2 </td>
                  <td>Self-installation kit</td>
                  <td>$15.00</td>
                </tr>
                <tr>
                  <td>3 </td>
                  <td>Reactivation fee</td>
                  <td>$6.00</td>
                </tr>
                <tr>
                  <td>4 </td>
                  <td>Late payment fee</td>
                  <td>$10.00</td>
                </tr>
                <tr>
                  <td>5 </td>
                  <td>xFi Gateway</td>
                  <td>$14.00/mo.</td>
                </tr>
                <tr>
                  <td>6 </td>
                  <td>Xfinity Flex</td>
                  <td>$0.00 (until April 2023)</td>
                </tr>
                <tr>
                  <td>7 </td>
                  <td>Data overage fee</td>
                  <td>$10 per 50 GB (up to $100.00/mo.)</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="package-table-container channels">
          <h4>Xfinity internet promotional cost: $19.99–$80.00/mo.*</h4>
          <p className="after-heading">
            <span className="link">
              <Link to={"#"}>Xfinity internet </Link>
            </span>{" "}
            packages range in price from $19.99–$80.00/mo.—there’s even a
            package that’s $299.95 per month for 6,000 Mbps, although it’s not
            widely available.
          </p>
          <p className="after-heading">
            Most packages are available with a choice of a one-year contract or
            no contract. In either case, there is a promo price for the first
            year, after which prices increase.
          </p>
          <ul>
            <li>· Connect: $19.99/mo.–$30.00/mo.* for 75 Mbps</li>
            <li>· Connect More: $25.99/mo.–$35.00* for 200 Mbps</li>
            <li>· Fast: $35.00/mo.–$55.00/mo.* for 400 Mbps</li>
            <li>· Superfast: $60.00–$65.00/mo.* for 800 Mbps</li>
            <li>· Gigabit/Ultrafast: $60.00/mo.–$75.00/mo.* for 1,000 Mbps</li>
            <li>· Gigabit Extra: $70.00/mo.–$80.00/mo.* for 1,200 Mbps</li>
          </ul>
          <p className="below-table">
            *Varies by region. For the first 12 months. **with no term agreement
          </p>
        </div>
        <div className="package-table-container channels">
          <h4>
            Xfinity price increase after promo period: up to an additional
            $30/mo.
          </h4>
          <p className="after-heading">
            The price increase following the promo period depends on which
            Xfinity internet plan you choose. Generally, increases are similar
            to those for TV—about $15–$30 per month.
          </p>
        </div>
        <div className="package-table-container channels">
          <h4>Taxes, regulatory fees, and more: vary by area and package</h4>
          <p className="after-heading">
            Unlike TV packages, Xfinity internet packages don’t usually come
            with taxes and other superfluous surcharges. In general, the
            Internet Tax Freedom Act doesn’t allow federal, state, or local
            governments to charge taxes on internet service.
            <br />
            If you bundle your internet service with TV, though, your bill will
            still include TV taxes and regulatory fees.
          </p>
        </div>
        <div className="package-table-container channels">
          <h4>
            Xfinity installation fees: $100.00 for professional installation,
            $15.00 for self-installation kit
          </h4>
          <p className="after-heading">
            Xfinity pushes its self-install kit for internet service, which
            costs $15.00—or $29.95 with priority shipping and as of the writing
            of this article.
            <br />
            If you need professional help, the fee is the same as it is for TV:
            $100.00. (But depending on the time of year, Xfinity may waive
            installation fees as part of a sign-up promotion.) The good news is
            that if you bundle both TV and internet, you’ll pay only one
            installation fee.
            <br />
            In some areas, specific bundles may even include professional
            installation at no extra cost, but that varies too much for us to
            nail down. Just ask your agent when you sign up to find out for
            sure. Check out our{" "}
            <span className="link">
              <Link to={"#"}>installation guide. </Link>
            </span>{" "}
            for more information on installation.
          </p>
        </div>
        <div className="package-table-container channels">
          <h4>
            Xfinity cancellation and early termination fees: may vary by area
            and plan
          </h4>
          <p className="after-heading">
            If you choose a package with a contract, you’ll be subject to early
            termination fees when you cancel your service early.
          </p>
          <p className="after-heading">
            As with Xfinity TV, the exact fees vary, but they reflect the
            remaining time in your contract. The cost of cancelling 10 months
            early will be much higher than cancelling only two months early, for
            example.
          </p>
          <div className="endnote endnote-start">
            <div className="endnote-container">
              <img src={quotationsIcon} alt="quotations" />
              <h4>Personal cancellation experience:</h4>
              <p>
                This is Rachel—your favorite writer—here to talk about my own
                experience with cancelling Xfinity early. I thought I was going
                to be living in my condo for a while longer, so I signed up for
                a tremendous two-year deal with Xfinity.
                <br />
                But then I decided to move, and I wasn’t sure I could get
                Xfinity in my new place. So I called customer service and found
                out that the cancellation fee for my internet contract would be
                $120—that’s $10 per remaining month. If I waited a month, it
                would go down to $110.
                <br />I can’t guarantee everyone’s Xfinity cancellation fee will
                be $10 per remaining month since Xfinity doesn’t expressly list
                this amount. But that sure is a bunch of money, and I’m glad I
                don’t have to pay it—I’ll be moving somewhere with Xfinity
                coverage, so I can take my plan with me.
                <br />
              </p>
            </div>
          </div>
        </div>
        <div className="package-table-container channels">
          <h4>Xfinity equipment fee: up to $14/mo. for xFi Gateway</h4>
          <p className="after-heading">
            With your internet plan, you can use your own modem and router.
            (Find the perfect ones for you with our{" "}
            <span className="link">
              <Link to={"#"}> list of compatible modems</Link>
            </span>
            and guide to the fastest routers.) But if you want to go with
            Xfinity’s modem/router, the xFi Gateway, you’ll need to pay as much
            as $14 per month
            <br />
            We recommend you poke around with a few packages before you decide.
            If you’d rather not pay for how much Xfinity charges for its modem,
            Xfinity has been known to include a modem/router or discount the{" "}
            <span className="link">
              <Link to={"#"}>xFi Gateway</Link>
            </span>
            when paired with some more expensive packages.
          </p>
        </div>
        <div className="package-table-container channels">
          <h4>Xfinity late payment fees: $10 per missed payment</h4>
          <p className="after-heading">
            As noted in the TV section above, Xfinity customers are subject to a
            $10 late fee for missed payments. Although there’s no grace period
            for Xfinity’s late payment policy, Xfinity does give you two weeks
            to pay your late fee before they start considering cutting off your
            service.
            <br />
            If you hit a rough spot and know in advance that your payment will
            be late (we’ve all been there), you can call ahead to possibly
            adjust the due date.
          </p>
        </div>
        <div className="package-table-container channels">
          <h4>How to cancel Xfinity service</h4>
          <p className="after-heading">
            The easiest way to cancel Xfinity service is to fill out a
            <span className="link">
              <Link to={"#"}>cancellation form online. </Link>
            </span>
            That way you don’t have to deal with pesky customer service
            representatives who might try to get you to stay.
            <br />
            That said, if you go the rounds with customer service, they may cut
            you a better deal. If this sounds appealing to you, contact Xfinity
            online to find out your options.
          </p>
        </div>
        <div className="package-table-container channels">
          <h4>Xfinity data overage fees: $10 per 50 GB block</h4>
          <p className="after-heading">
            Most internet plans come with a 1.2 TB data cap. That means you can
            use up to 1.2 TB of data each month, after which you’ll be charged a
            fee.
            <br />
            Xfinity charges a standard $10 for every 50 GB block of data over
            the cap—even if you don’t use the whole 50 GB block. But it will
            charge you only up to $200 in these overage fees—oh wait, no, that’s
            nothing to be excited about because it’s still a ton of money.
            <br />
            Most people will probably never reach the data cap—1.2 TB is a good
            bit of data. Still, heavy downloaders or streamers who are afraid of
            running into these overage charges should consider buying the $50
            add-on for unlimited data or springing for the unlimited Gigabit Pro
            package.
          </p>
        </div>
        <div className="endnote endnote-start">
          <div className="endnote-container">
            <img src={quotationsIcon} alt="quotations" />
            <h4>Cheapest Xfinity internet package we recommend</h4>
            <p>
              ecommend the Connect More plan for low-cost Xfinity internet. This
              package offers 200 Mbps speeds for $39.99 per month for 12 months
              with a one-year contract.
              <br />
              If you’d prefer to bundle internet and TV together, the Popular
              Double Play bundle is a good, low-cost option. It offers 125+
              channels and 400 Mbps internet download speeds for $110.70 per
              month for 12 months with a one year agreement.
            </p>
            <br />
            <div className="btn">
              <Link to={"#"}>view xfinity bundle plans</Link>
            </div>
          </div>
        </div>
      </div>

      <div className="section xfinity-hidden-cost-review-faq">
        <div className="head">
          <h2 className="sect-heading">
            Xfinity <span>fees and hidden costs </span>FAQ
          </h2>

          <p className="sect-desc">
            TL;DR? Xfinity kills it. For more detailed info on all Xfinity
            equipment and services, see our comprehensive Xfinity equipment
            guide.
          </p>
        </div>

        <div className="faq-wrapper">
          <div className="accordion">
            <div className="item">
              <h3 className="title" onClick={() => accordionHandler(1)}>
                <span>{faq === 1 ? "-" : "+"}</span> What are Xfinity tV
                packages?
              </h3>

              <div className={`faq-content ${faq === 1 ? "show" : ""}`}>
                <p>
                  Xfinity TV packages by Comcast come through a cable connection
                  with up to 185+ channels. Most packages also include 20 hours
                  of DVR storage via the{" "}
                  <span className="link">
                    <Link to={"#"}>X1 DVR box, </Link>
                  </span>{" "}
                  plus the Xfinity Stream app.
                </p>
                <ul>
                  <li>· Choice TV: $20.00–$25.00/mo., 10+ channels</li>
                  <li>· Popular TV: $50.00–$60.00/mo., 125+ channels</li>
                  <li>· Ultimate TV: $68.50–$80.00/mo., 185+ channels</li>
                </ul>
                <p>
                  Check to see what Xfinity TV packages are available in your
                  area.
                </p>
              </div>
            </div>
          </div>

          <div className="accordion">
            <div className="item">
              <h3 className="title" onClick={() => accordionHandler(2)}>
                <span>{faq === 2 ? "-" : "+"}</span> What is the cheapest
                xfinity tV Package?
              </h3>

              <div className={`faq-content ${faq === 2 ? "show" : ""}`}>
                <p>
                  The cheapest Xfinity internet plan is 75 Mbps for $19.99 in
                  the West region, 75 Mbps for $30.00 in the Central region, and
                  200 Mbps for $25.00/mo. in the Northeast region.
                  Unfortunately, plan pricing and naming varies by region.
                </p>
              </div>
            </div>
          </div>

          <div className="accordion">
            <div className="item">
              <h3 className="title" onClick={() => accordionHandler(3)}>
                <span>{faq === 3 ? "-" : "+"}</span> Can you get xfinity tV
                without cable?
              </h3>

              <div className={`faq-content ${faq === 3 ? "show" : ""}`}>
                <p>
                  Sorry, after April 2023, the{" "}
                  <span className="link">
                    <Link to={"#"}>Xfinity Flex </Link>
                  </span>{" "}
                  won't be free anymore. If you don't mind paying whatever its
                  new price will be, you can request it when you sign up for
                  your Xfinity internet plan (although your customer service rep
                  will probably railroad you into getting it before you get a
                  word in edgewise).
                  <br />
                  However, if you get an{" "}
                  <span className="link">
                    <Link to={"#"}>Xfinity TV plan</Link>
                  </span>
                  , you're not eligible for an Xfinity Flex. That's okay-ish
                  since you'll get the X1 DVR instead. Unfortunately, the X1
                  falls into the non-free category as well due to its $14.95 per
                  month service fee.
                </p>
              </div>
            </div>
          </div>

          <div className="accordion">
            <div className="item">
              <h3 className="title" onClick={() => accordionHandler(4)}>
                <span>{faq === 4 ? "-" : "+"}</span> what is the best package to
                get on xfinity tV?
              </h3>

              <div className={`faq-content ${faq === 4 ? "show" : ""}`}>
                <p>
                  Comcast and Xfinity are the same thing-you can't trip us up
                  with your trick question!
                </p>
                <p>
                  In 2010, Comcast started using Xfinity as its trade name for
                  TV, internet, and phone services.
                  <br />
                  And as part of Comcast, Xfinity has built-in relationships
                  with Comcast's other products, such as NBCUniversal. That's
                  one of the reasons you'll get the{" "}
                  <span className="link">
                    <Link to={"#"}>Peacock Premium</Link>
                  </span>{" "}
                  streaming service for free with your Xfinity cable TV service.
                </p>
              </div>
            </div>
          </div>

          <div className="accordion">
            <div className="item">
              <h3 className="title" onClick={() => accordionHandler(5)}>
                <span>{faq === 5 ? "-" : "+"}</span>can you just get xfinity tV?
              </h3>

              <div className={`faq-content ${faq === 5 ? "show" : ""}`}>
                <p>
                  The best Xfinity deal is Superfast Internet + Ultimate TV,
                  which provides 800 Mbps download speeds and 185+ cable
                  channels. Your savings may vary by region, though, and
                  depending on where you live, it could cost $115-$120 a month.
                </p>
                <p>
                  For more on Xfinity's money-saving double plays, head over to
                  our{" "}
                  <span className="link">
                    <Link to={"#"}>Xfinity bundles review</Link>
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className="accordion">
            <div className="item">
              <h3 className="title" onClick={() => accordionHandler(6)}>
                <span>{faq === 6 ? "-" : "+"}</span>what channels are in the
                xfinity’s basic package?
              </h3>

              <div className={`faq-content ${faq === 6 ? "show" : ""}`}>
                <p>
                  According to our{" "}
                  <span className="link">
                    <Link to={"#"}>customer satisfaction survey,</Link>
                  </span>{" "}
                  62% of Xfinity customers are satisfied with their internet
                  service. In addition, 69% of Xfinity customers are satisfied
                  with their internet speed, tying it for first place with
                  Verizon. Cable service will always be dependent on how heavy
                  usage is in your network, so for more reliable speeds, we
                  recommend{" "}
                  <span className="link">
                    <Link to={"#"}>fiber internet</Link>
                  </span>
                  if you have the option.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section endnote intertnet-review-endnote">
        <div className="endnote-container">
          <img className="quotation-img" src={quotations} alt="quotations" />
          <h4>Methodology:</h4>
          <p>
            Our experts poured over Xfinity cable TV and internet pricing
            information for multiple regions to uncover hard-to-find fees. For
            more information on our methodology, check out our{" "}
            <span className="link">
              <Link to={"#"}>How We Rank </Link>
            </span>{" "}
            page.
          </p>
          <h4>Related articles</h4>
          <ul>
            <li>
              <span className="link">
                <Link to={"#"}>· Hidden Costs of Spectrum </Link>
              </span>
            </li>
            <li>
              {" "}
              <span className="link">
                <Link to={"#"}>· Hidden Costs of AT&T</Link>
              </span>
            </li>
            <li>
              {" "}
              <span className="link">
                <Link to={"#"}>· Hidden Costs of Cox</Link>
              </span>
            </li>
          </ul>
        </div>
      </div>
      <Newsletter />
    </div>
  );
};

export default XfinityHiddenCosts;
