import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/main/home/Home";
import Tv from "../pages/main/tv/Tv";
import Internet from "../pages/main/internet/Internet";
import Streaming from "../pages/main/streaming/Streaming";
import Resources from "../pages/main/resources/Resources";
import WhatToWatch from "../pages/main/what-to-watch/WhatToWatch";
import XfinityReview from "../pages/xfinity/review/XfinityReview";
import XfinityInternet from "../pages/xfinity/internet/XfinityInternet";
import XfinityTv from "../pages/xfinity/tv/XfinityTv";
import XfinityDeals from "../pages/xfinity/deals/XfinityDeals";
import XfinityChannelLinup from "../pages/xfinity/channel-linup/XfinityChannelLinup";
import XfinityMobile from "../pages/xfinity/mobile/XfinityMobile";
import XfinityBundles from "../pages/xfinity/bundles/XfinityBundles";
import XfinityVoice from "../pages/xfinity/voice/XfinityVoice";
import XfinityAvailability from "../pages/xfinity/availability/XfinityAvailability";
import XfinityHiddenCosts from "../pages/xfinity/hidden-costs/XfinityHiddenCosts";
import XfinityCustomerService from "../pages/xfinity/customer-service/XfinityCustomerService";
import XfinityInternetReview from "../pages/xfinity/internet-review/XfinityInternetReview";
import XfinityTvReview from "../pages/xfinity/tv-review/XfinityTvReview";
import XfinityBundlesReview from "../pages/xfinity/bundles-review/XfinityBundlesReview";
import SpectrumReview from "../pages/spectrum/review/SpectrumReview";
import SpectrumInternet from "../pages/spectrum/internet/SpectrumInternet";
import SpectrumTv from "../pages/spectrum/tv/SpectrumTv";
import SpectrumDeals from "../pages/spectrum/deals/SpectrumDeals";
import SpectrumBundles from "../pages/spectrum/bundles/SpectrumBundles";
import SpectrumMobile from "../pages/spectrum/mobile/SpectrumMobile";
import SpectrumChannelLineUps from "../pages/spectrum/channel-lineups/SpectrumChannelLineUps";
import SpectrumVoice from "../pages/spectrum/voice/SpectrumVoice";
import SpectrumAvailability from "../pages/spectrum/availability/SpectrumAvailability";
import SpectrumInternetReview from "../pages/spectrum/internet-review/SpectrumInternetReview";
import SpectrumTvReview from "../pages/spectrum/tv-review/SpectrumTvReview";
import SpectrumInternetCheapPlan from "../pages/spectrum/internet-plan/SpectrumInternetCheapPlan";
import SpectrumBundlesReview from "../pages/spectrum/bundles-review/SpectrumBundlesReview";
import SpectrumMobileReview from "../pages/spectrum/mobile-review/SpectrumMobileReview";
import Dish from "../pages/dish/shop/Dish";
import DishReview from "../pages/dish/review/DishReview";
import DishInternet from "../pages/dish/internet/DishInternet";
import DishDeals from "../pages/dish/deals/DishDeals";
import DishChannelLineUp from "../pages/dish/channel-lineup/DishChannelLineUp";
import DishLatinoEspanol from "../pages/dish/latino-espanol/DishLatinoEspanol";
import DishLatino from "../pages/dish/latino/DishLatino";
import DishAnywhereApp from "../pages/dish/anywhere-app/DishAnywhereApp";
import { DishHopper } from "../pages/dish/hopper-3/DishHopper";
import DishCustomerService from "../pages/dish/customer-service/DishCustomerService";
import DirectTvReview from "../pages/direct-tv/review/DirectTvReview";
import DirectTvSports from "../pages/direct-tv/sports/DirectTvSports";
import DirectTvStream from "../pages/direct-tv/stream/DirectTvStream";
import DirectTvTroubleshootingGuide from "../pages/direct-tv/troubleshooting-guide/DirectTvTroubleshootingGuide";
import DirectTvNFLSundayTicket from "../pages/direct-tv/nfl-sunday-ticket/DirectTvNFLSundayTicket";
import DirectTvHiddenCosts from "../pages/direct-tv/hidden-costs/DirectTvHiddenCosts";
import DirectTvGenie from "../pages/direct-tv/genie/DirectTvGenie";
import DirectTvDeals from "../pages/direct-tv/deals/DirectTvDeals";
import DirectTvCustomerService from "../pages/direct-tv/customer-service/DirectTvCustomerService";
import DirectTvChannelLineUp from "../pages/direct-tv/channel-lineup/DirectTvChannelLineUp";
import DirectTvBundles from "../pages/direct-tv/bundles/DirectTvBundles";
import OptimumReview from "../pages/optimum/review/OptimumReview";
import OptimumAvailabilityMap from "../pages/optimum/availability/OptimumAvailabilityMap";
import OptimumBundleReview from "../pages/optimum/bundle-review/OptimumBundleReview";
import OptimumBundles from "../pages/optimum/bundles/OptimumBundles";
import OptimumChannelLineUp from "../pages/optimum/channel-lineup/OptimumChannelLineUp";
import OptimumCustomerService from "../pages/optimum/customer-service/OptimumCustomerService";
import OptimumDeals from "../pages/optimum/deals/OptimumDeals";
import OptimumHiddenCosts from "../pages/optimum/hidden-costs/OptimumHiddenCosts";
import OptimumInternet from "../pages/optimum/internet/OptimumInternet";
import OptimumInternetReview from "../pages/optimum/internet-review/OptimumInternetReview";
import OptimumMobile from "../pages/optimum/mobile/OptimumMobile";
import OptimumShopMobile from "../pages/optimum/shop-mobile/OptimumShopMobile";
import OptimumSmartWifi from "../pages/optimum/smart-wifi/OptimumSmartWifi";
import OptimumTv from "../pages/optimum/tv/OptimumTv";
import OptimumTvReview from "../pages/optimum/tv-review/OptimumTvReview";
import CoxReview from "../pages/cox/review/CoxReview";
import CoxInternetReview from "../pages/cox/internet-review/CoxInternetReview";
import CoxTv from "../pages/cox/tv/CoxTv";
import CoxDeals from "../pages/cox/deals/CoxDeals";
import CoxBundleReview from "../pages/cox/bundle-review/CoxBundleReview";
import CoxChannelLineUp from "../pages/cox/channel-lineup/CoxChannelLineUp";
import CoxAvailability from "../pages/cox/availability/CoxAvailability";
import CoxInstallationGuide from "../pages/cox/installation-guide/CoxInstallationGuide";
import CoxTroubleShootingGuide from "../pages/cox/troubleshooting-guide/CoxTroubleShootingGuide";
import CoxCustomerService from "../pages/cox/customer-service/CoxCustomerService";
import CoxInternet from "../pages/cox/internet/CoxInternet";
import CoxBundles from "../pages/cox/bundles/CoxBundles";
import VerizonReview from "../pages/verizon/review/VerizonReview";
import ShopVerizonFiosInternet from "../pages/verizon/fios-internet/ShopVerizonFiosInternet";
import Verizon5gHomeInternetReview from "../pages/verizon/5g-home-internet-review/Verizon5gHomeInternetReview";
import ShopVerizon5gHomeInternet from "../pages/verizon/5g-home-internet/ShopVerizon5gHomeInternet";
import ShopVerizonFiosTv from "../pages/verizon/fios-tv/ShopVerizonFiosTv";
import ShopVerizonWireless from "../pages/verizon/wireless/ShopVerizonWireless";
import ShopVerizonDeals from "../pages/verizon/deals/ShopVerizonDeals";
import ShopVerizonBundles from "../pages/verizon/bundles/ShopVerizonBundles";
import VerizonChannelLineup from "../pages/verizon/channel-lineup/VerizonChannelLineup ";
import VerizonFiosPhone from "../pages/verizon/fios-phone/VerizonFiosPhone";
import VerizonCustomerService from "../pages/verizon/customer-service/VerizonCustomerService";
import VerizonFiosInternetReview from "../pages/verizon/fios-internet-review/VerizonFiosInternetReview";
import VerizonFiosTvReview from "../pages/verizon/fios-tv-review/VerizonFiosTvReview";
import VerizonFiosBundlesReview from "../pages/verizon/fios-bundles-review/VerizonFiosBundlesReview";
import AtandtReview from "../pages/at&t/review/At&tReview";
import AtandtInternet from "../pages/at&t/internet/At&tInternet";
import AtandtDeals from "../pages/at&t/deals/At&tDeals";
import AtandtBundles from "../pages/at&t/bundles/At&tBundles";
import AtandtWireless from "../pages/at&t/wireless/At&tWireless";
import AtandtBusiness from "../pages/at&t/business/At&tBusiness";
import AtandtHiddenCost from "../pages/at&t/hidden-costs/At&tHiddenCost";
import AtandtTroubleshootingGuide from "../pages/at&t/troubleshooting-guide/At&tTroubleshootingGuide";
import AtandtCustomerService from "../pages/at&t/customer-service/At&tCustomerService";
import AtandtInternetReveiw from "../pages/at&t/internet-review/At&tInternetReveiw";
import AtandtInternetAirReview from "../pages/at&t/internet-air-review/At&tInternetAirReview";
import CheapAtandtInternet from "../pages/at&t/cheap-at&t-internet/CheapAt&tInternet";
import AtandtWirelessReview from "../pages/at&t/wireless-review/At&tWirelessReview";
import AtandtBundlesReview from "../pages/at&t/bundles-review/At&tBundlesReview";


const Routers = () => {
  return (
    <Routes>
      {/* Main Pages */}
      <Route path="/" element={<Home />} />
      <Route path="/tv" element={<Tv />} />
      <Route path="/internet" element={<Internet />} />
      <Route path="/streaming" element={<Streaming />} />
      <Route path="/what-to-watch" element={<WhatToWatch />} />
      <Route path="/resources" element={<Resources />} />

      {/* Xfinity Pages */}
      <Route path="/xfinity" element={<XfinityReview />} />
      <Route path="/xfinity/internet" element={<XfinityInternet />} />
      <Route path="/xfinity/cable-tv" element={<XfinityTv />} />
      <Route path="/xfinity/promotions" element={<XfinityDeals />} />
      <Route path="/xfinity/channel-linup" element={<XfinityChannelLinup />} />
      <Route path="/xfinity/mobile" element={<XfinityMobile />} />
      <Route path="/xfinity/bundles" element={<XfinityBundles />} />
      <Route path="/xfinity/voice" element={<XfinityVoice />} />
      <Route path="/xfinity/availability" element={<XfinityAvailability />} />
      <Route path="/xfinity/hidden-costs" element={<XfinityHiddenCosts />} />
      <Route path="/xfinity/phone-number" element={<XfinityCustomerService />} />
      <Route path="/xfinity/internet-review" element={<XfinityInternetReview />} />
      <Route path="/xfinity/tv-review" element={<XfinityTvReview />} />
      <Route path="/xfinity/bundles-review" element={<XfinityBundlesReview />} />

      {/* Spectrum Pages */}
      <Route path="/spectrum" element={<SpectrumReview/>} />
      <Route path="/spectrum/internet" element={<SpectrumInternet/>} />
      <Route path="/spectrum/cabel-tv" element={<SpectrumTv/>} />
      <Route path="/spectrum/promotions" element={<SpectrumDeals/>} />
      <Route path="/spectrum/bundles" element={<SpectrumBundles/>} />
      <Route path="/spectrum/mobile" element={<SpectrumMobile/>} />
      <Route path="/spectrum/channel-lineup" element={<SpectrumChannelLineUps/>} />
      <Route path="/spectrum/voice" element={<SpectrumVoice/>} />
      <Route path="/spectrum/availability" element={<SpectrumAvailability/>} />
      <Route path="/spectrum/internet-review" element={<SpectrumInternetReview/>} />
      <Route path="/spectrum/tv-review" element={<SpectrumTvReview/>} />
      <Route path="/spectrum/internet-cheap-plan" element={<SpectrumInternetCheapPlan/>} />
      <Route path="/spectrum/bundles-review" element={<SpectrumBundlesReview/>} />
      <Route path="/spectrum/mobile-review" element={<SpectrumMobileReview/>} />

      {/* Dish Pages */}
      <Route path="/dish/" element={<Dish/>}/>
      <Route path="/dish/review" element={<DishReview/>}/>
      <Route path="/dish/internet" element={<DishInternet/>}/>
      <Route path="/dish/deals" element={<DishDeals/>}/>
      <Route path="/dish/channel-lineup" element={<DishChannelLineUp/>}/>
      <Route path="/dish/latino-espanol" element={<DishLatinoEspanol/>}/>
      <Route path="/dish/latino" element={<DishLatino/>}/>
      <Route path="/dish/anywhere-app" element={<DishAnywhereApp/>}/>
      <Route path="/dish/hopper-3" element={<DishHopper/>}/>
      <Route path="/dish/phone-number" element={<DishCustomerService/>}/>

       {/* Direct Tv Pages */}
       <Route path="/directtv" element={<DirectTvReview/>}/>
       <Route path="/directtv/sports" element={<DirectTvSports/>}/>
       <Route path="/directtv/stream" element={<DirectTvStream/>}/>
       <Route path="/directtv/troubleshooting" element={<DirectTvTroubleshootingGuide/>}/>
       <Route path="/directtv/nfl-sunday-ticket" element={<DirectTvNFLSundayTicket/>}/>
       <Route path="/directtv/hidden-costs" element={<DirectTvHiddenCosts/>}/>
       <Route path="/directtv/genie" element={<DirectTvGenie/>}/>
       <Route path="/directtv/deals" element={<DirectTvDeals/>}/>
       <Route path="/directtv/phone-number" element={<DirectTvCustomerService/>}/>
       <Route path="/directtv/channel-lineup" element={<DirectTvChannelLineUp/>}/>
       <Route path="/directtv/bundles" element={<DirectTvBundles/>}/>

       {/* Optimum Pages */}
       <Route path="/optimum" element={<OptimumReview/>}/>
       <Route path="/optimum/availability" element={<OptimumAvailabilityMap/>}/>
       <Route path="/optimum/bundle-review" element={<OptimumBundleReview/>}/>
       <Route path="/optimum/bundles" element={<OptimumBundles/>}/>
       <Route path="/optimum/channel-lineup" element={<OptimumChannelLineUp/>}/>
       <Route path="/optimum/phone-number" element={<OptimumCustomerService/>}/>
       <Route path="/optimum/deals" element={<OptimumDeals/>}/>
       <Route path="/optimum/hidden-costs" element={<OptimumHiddenCosts/>}/>
       <Route path="/optimum/internet" element={<OptimumInternet/>}/>
       <Route path="/optimum/internet-review" element={<OptimumInternetReview/>}/>
       <Route path="/optimum/mobile" element={<OptimumMobile/>}/>
       <Route path="/optimum/review" element={<OptimumReview/>}/>
       <Route path="/optimum/shop-mobile" element={<OptimumShopMobile/>}/>
       <Route path="/optimum/smart-wifi" element={<OptimumSmartWifi/>}/>
       <Route path="/optimum/tv" element={<OptimumTv/>}/>

       {/* Cox Pages */}
       <Route path="/cox" element={<CoxReview/>}/> 
       <Route path="/cox/internet-review" element={<CoxInternetReview/>}/> 
       <Route path="/cox/tv" element={<CoxTv/>}/> 
       <Route path="/cox/deals" element={<CoxDeals/>}/> 
       <Route path="/cox/bundle-review" element={<CoxBundleReview/>}/> 
       <Route path="/cox/channel-lineup" element={<CoxChannelLineUp/>}/> 
       <Route path="/cox/availability" element={<CoxAvailability/>}/> 
       <Route path="/cox/installation-guide" element={<CoxInstallationGuide/>}/> 
       <Route path="/cox/troubleshooting" element={<CoxTroubleShootingGuide/>}/> 
       <Route path="/cox/phone-number" element={<CoxCustomerService/>}/> 
       <Route path="/cox/internet" element={<CoxInternet/>}/> 
       <Route path="/cox/bundles" element={<CoxBundles/>}/> 

        {/* Verizon Pages */}
        <Route path="/verizon" element={<VerizonReview/>}/> 
        <Route path="/verizon/fios-internet" element={<ShopVerizonFiosInternet/>}/> 
        <Route path="/verizon/5g-home-internet-review" element={<Verizon5gHomeInternetReview/>}/> 
        <Route path="/verizon/5g-home-internet" element={<ShopVerizon5gHomeInternet/>}/> 
        <Route path="/verizon/fios-tv" element={<ShopVerizonFiosTv/>}/> 
        <Route path="/verizon/wireless" element={<ShopVerizonWireless/>}/> 
        <Route path="/verizon/deals" element={<ShopVerizonDeals/>}/> 
        <Route path="/verizon/bundles" element={<ShopVerizonBundles/>}/> 
        <Route path="/verizon/channel-lineup" element={<VerizonChannelLineup/>}/> 
        <Route path="/verizon/fios-phone" element={<VerizonFiosPhone/>}/> 
        <Route path="/verizon/phone-number" element={<VerizonCustomerService/>}/> 
        <Route path="/verizon/fios-internet-review" element={<VerizonFiosInternetReview/>}/> 
        <Route path="/verizon/fios-tv-review" element={<VerizonFiosTvReview/>}/> 
        <Route path="/verizon/fios-bundles-review" element={<VerizonFiosBundlesReview/>}/> 

          {/* AT&T Pages */}
        <Route path="/at&t" element={<AtandtReview/>}/> 
        <Route path="/at&t/internet" element={<AtandtInternet/>}/> 
        <Route path="/at&t/deals" element={<AtandtDeals/>}/> 
        <Route path="/at&t/bundles" element={<AtandtBundles/>}/> 
        <Route path="/at&t/wireless" element={<AtandtWireless/>}/> 
        <Route path="/at&t/business" element={<AtandtBusiness/>}/> 
        <Route path="/at&t/hidden-costs" element={<AtandtHiddenCost/>}/> 
        <Route path="/at&t/troubleshooting" element={<AtandtTroubleshootingGuide/>}/> 
        <Route path="/at&t/phone-number" element={<AtandtCustomerService/>}/> 
        <Route path="/at&t/internet-review" element={<AtandtInternetReveiw/>}/> 
        <Route path="/at&t/internet-air-review" element={<AtandtInternetAirReview/>}/> 
        <Route path="/at&t/cheap-internet" element={<CheapAtandtInternet/>}/> 
        <Route path="/at&t/wireless-review" element={<AtandtWirelessReview/>}/> 
        <Route path="/at&t/bundles-review" element={<AtandtBundlesReview/>}/> 

    </Routes>
  );
};

export default Routers;
