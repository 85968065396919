import React, { useState } from "react";
import "./xfinity-bundles-review.scss";
import SubNav from "../../../components/sub-nav/SubNav";
import { xfinityNavLinks } from "../../../assets/data/navLinks/xfinityNavLinks";
import InnerPagesHeroTwo from "../../../components/inner-pages-hero-two/InnerPagesHeroTwo";
import StickyHeader from "../../../components/sticky-header/StickyHeader";
import xfinitylogo from "../../../assets/images/xfinitylogo.png";
import star from "../../../assets/images/star.png";
import { GoArrowUpRight } from "react-icons/go";
import cross from "../../../assets/images/cross.png";
import reviewmanthree from "../../../assets/images/reviewmanthree.png";
import quotationsIcon from "../../../assets/images/quotations.svg";
import { IoThumbsDownSharp, IoThumbsUpSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
import discountIcon from "../../../assets/images/discount-icon.svg";
import PackageTable from "../../../components/package-table/PackageTable";
import quotations from "../../../assets/images/quotations.svg";
import Newsletter from "../../../components/newsletter/Newsletter";

const XfinityBundlesReview = () => {
  const [faq, setFaq] = useState();

  let heroData = {
    card1: {
      logo: xfinitylogo,
      rating: "(4.5/5)",
      starImg: star,
      desc: "Get high-speed internet for only $30/mo. when you add Xfinity Mobile to your plan.",
      buttonText: "Compare Plans",
      linkPath: "#",
      buttonIcon: <GoArrowUpRight className="check-icon" />,
    },

    card2Img: reviewmanthree,

    cardsStrip: [
      {
        subtitle: "Service Provider:",
        title: "xfinity",
        icon: cross,
      },
      {
        subtitle: "Price:",
        title: "$35.00–$150.00/mo.",
        icon: cross,
      },
      {
        subtitle: "Channels:",
        title: "10–185+",
        icon: cross,
      },
      {
        subtitle: "Internet speed:",
        title: "75–6,000 Mbps",
        icon: cross,
      },
    ],

    tableData: [
      {
        head: [
          "Top packages",
          "Base price*",
          "Download speeds",
          "Channels",
          "Phone plan",
          "Details",
        ],

        body: [
          {
            content: [
              "1.",
              "Fast Internet + Popular TV",
              "$85.00–$90.00/mo.",
              "400 Mbps",
              "125+",
              "N/A",
            ],
            linkPath: "#",
          },

          {
            content: [
              "2.",
              "Superfast Internet + Ultimate TV",
              "$110.00–$120.00/mo.",
              "800 Mbps",
              "185+",
              "N/A",
            ],
            linkPath: "#",
          },
          {
            content: [
              "3.",
              "Gigabit Extra + Ultimate TV + Phone",
              "1200 Mbps",
              "125+",
              "185+",
              "Unlimited local calling",
            ],
            linkPath: "#",
          },
        ],
      },
    ],

    bottomDesc:
      "*Internet: For 24 months. No term contract. Taxes not included. Includes $10/mo automatic payments and paperless billing discount. Not available in all areas. TV: No term contract. Broadcast TV fee and regional sports fee extra; price subj to change. Taxes and equipment not included. Data effective as of publish date. Offers and availability may vary by location and are subject to change.",
  };

  let packageTableData = [
    {
      head: [
        "Top packages",
        "Base price*",
        "Download speeds",
        "Channels",
        " Phone plan",
        "Details",
      ],

      body: [
        {
          content: [
            "1.",
            "Fast Internet + Popular TV",
            "$85.00/mo.",
            "400 Mbps",
            "125+",
            "N/A",
          ],
          linkPath: "#",
        },
        {
          content: [
            "2.",
            "Superfast Internet + Ultimate TV",
            "$110.00–$120.00/mo.",
            "800 Mbps",
            "185+",
            "N/A",
          ],
          linkPath: "#",
        },
        {
          content: [
            "3.",
            "Gigabit Extra + Ultimate TV + Phone",
            "1200 Mbps",
            "125+",
            "185+",
            "Unlimited local calling",
          ],
          linkPath: "#",
        },
      ],
    },
  ];
  let packageTableDataTwo = [
    {
      head: [
        "Plan",
        "Internet price",
        "Download speeds",
        "TV price",
        " Channels",
        " Price increase after 12 mo.",
        "Details",
      ],

      body: [
        {
          content: [
            "1.",
            "Xfinity",
            "$19.99–$80.00/mo.",
            "75–6,000 Mbps",
            "$20.00–$80.00/mo.",
            "10–185+",
            "Some plans",
          ],
          linkPath: "#",
        },
        {
          content: [
            "2.",
            "Spectrum",
            "$19.99–$80.00/mo.",
            "Up to 300–1,000 Mbps",
            "$59.99/mo.",
            "150+",
            "Yes",
          ],
          linkPath: "#",
        },
        {
          content: [
            "3.",
            "Cox",
            "$49.99–$149.99/mo.",
            "100–2,000 Mbps",
            "$56.00–$139.00/mo.",
            "75–250+ ",
            "Yes",
          ],
          linkPath: "#",
        },
        {
          content: [
            "4.",
            "Optimum",
            "$40.00–$180.00/mo.",
            "300–5,000 Mbps",
            "$35.00–$115.00/mo.",
            "50–420+",
            "Yes",
          ],
          linkPath: "#",
        },
      ],
    },
  ];
  let accordionHandler = (i) => {
    if (faq === i) {
      return setFaq(null);
    }

    setFaq(i);
  };
  return (
    <div className="xfinity-bundles-review">
      <SubNav links={xfinityNavLinks} />
      <div className="section inner-hero">
        <div className="head">
          <h2 className="sect-heading">
            Xfinity Packages: TV And Internet Bundles Review
          </h2>
          <p className="sect-desc">
            You can save $20–$60 per month with an Xfinity bundle.
          </p>
        </div>
        <InnerPagesHeroTwo data={heroData} />
      </div>
      <StickyHeader />

      <div className="endnote is-xfinity-good">
        <div className="endnote-container">
          <img src={quotationsIcon} alt="quotations" />
          <h4>Are Xfinity bundles good?</h4>
          <p>
            Xfinity bundles and Xfinity TV packages hold their own as standalone
            services, and combining them in an Xfinity bundle will save you $20
            a month.
            <br />
            Those savings are enticing, but we wish that Xfinity offered a bit
            more sparkle—other cable companies include unlimited data or free
            equipment when you bundle, but Xfinity makes you jump through hoops
            to get additional savings. At least you’ll have access to Xfinity’s
            huge Wi-Fi hotspot network and a free Peacock Premium account.
          </p>
          <br />
          <h4>Xfinity bundles pros and cons</h4>
          <div className="custom-table">
            <table>
              <thead>
                <tr>
                  <th>
                    Prospects <IoThumbsUpSharp className="icon" />
                  </th>
                  <th>
                    Consequences <IoThumbsDownSharp className="icon" />
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>Monthly savings</td>
                  <td>Data caps</td>
                </tr>
                <tr>
                  <td>Xfinity Wi-Fi hotspots</td>
                  <td>Expensive equipment</td>
                </tr>
                <tr>
                  <td>Free Peacock Premium with X1 DVR</td>
                  <td>Some plans with contracts</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="section important-links">
        <div className="head">
          <h2 className="sect-heading">
            Jump to <span>important links</span>
          </h2>
          <p className="sect-desc">
            Lorem ipsum dolor sit amet consectetur. Sit purus quis porttitor
            scelerisque sem tempor netus. Nisi diam pulvinar pharetra lacus in a
            pellentesque nunc consequat. Commodo elit dignissim vulputate
            cursus. Dictum in morbi vestibulum posuere placerat pellentesque
            eros congue molestie.
          </p>
        </div>

        <div className="all-links">
          <Link to={"#"}>Deals</Link>
          <Link to={"#"}>Plans & prices</Link>
          <Link to={"#"}>competition</Link>
          <Link to={"#"}>specs and features</Link>
        </div>
      </div>

      <div className="section xfinity-package-deals">
        <div className="head">
          <h2 className="sect-heading">
            Xfinity package <span> deals and promotions</span>
          </h2>
          <p className="sect-desc">
            You’ll save a lot each month with an Xfinity bundle deal. The
            Xfinity two-service deal is $20 off per month, and the Xfinity
            triple service deal is $40 off per month. You’ll get a total of $60
            off per month if you add Xfinity home security. On top of that, if
            you go with Xfinity’s X1 DVR for your TV equipment, you’ll get a
            free membership to{" "}
            <span className="link">
              <Link to={"#"}>Peacock Premium,</Link>
            </span>{" "}
            a package from NBC’s on-demand streaming service. If you add an xFi
            Gateway with your internet plan (Fast or higher), you can also get
            free unlimited data for two years. If your household is looking for
            affordable smartphone service, you can also bundle{" "}
            <span className="link">
              <Link to={"#"}>Xfinity Mobile</Link>
            </span>{" "}
            for even more savings.
            <br />
            <br />
            And when you’re signing up on the Xfinity webpage, see if your
            Xfinity bundle deal includes promotions like a gift card. We can’t
            guarantee you’ll snag a $200 Visa® Prepaid Card with your package,
            but Xfinity has deals like that every so often.
            <br />
            <br />
            Check out our{" "}
            <span className="link">
              <Link to={"#"}>Xfinity deals guide</Link>
            </span>{" "}
            to learn more about the best Xfinity deals.
          </p>
        </div>
        <div className="endnote limited-time-offer">
          <div className="endnote-container">
            <img src={discountIcon} alt="discount" />
            <h4>Xfinity bundle deals</h4>
            <br />
            <ul>
              <li>· $20–$60/mo. off when you bundle Xfinity services</li>
              <li>· Peacock Premium free with X1 DVR</li>
              <li>· Gift card occasionally offered with qualifying plans</li>
            </ul>
            <hr />
            <div className="btn">
              <Link to={"#"}>get these deals</Link>
            </div>
          </div>
        </div>
      </div>

      <div className="section xfinity-internet-bundles">
        <div className="head">
          <h2 className="sect-heading">
            Xfinity TV and internet bundles <span> in your area</span>
          </h2>
          <p className="sect-desc">
            Lorem ipsum dolor sit amet consectetur. Nec quis venenatis nec ut
            lectus risus sed id a. Feugiat justo quam mi sed elementum facilisi
            libero id felis. Dictum bibendum et in faucibus nisi consectetur
            sollicitudin ullamcorper tristique. Gravida porttitor fermentum
            pretium in at.
          </p>
        </div>
        <div className="package-table-container channels">
          <h4>Xfinity TV, internet, and home phone bundles (West)</h4>
          <PackageTable item={packageTableData} />
          <p className="below-table">
            *Internet: For 24 months. No term contract. Taxes not included.
            Includes $10/mo automatic payments and paperless billing discount.
            Not available in all areas. TV: No term contract. Broadcast TV fee
            and regional sports fee extra; price subj to change. Taxes and
            equipment not included. Data effective as of publish date. Offers
            and availability may vary by location and are subject to change.
          </p>
        </div>
        <div className="faq-wrapper">
          <div className="accordion">
            <div className="item">
              <h3 className="title" onClick={() => accordionHandler(1)}>
                <span>{faq === 1 ? "-" : "+"}</span> Xfinity TV, internet and
                home phone bundles (Central)
              </h3>

              <div className={`faq-content ${faq === 1 ? "show" : ""}`}>
                <p>
                  Xfinity is available in most US states, but you'll have to
                  check if it services your neighborhood. Enter your{" "}
                  <span className="link">
                    <Link to={"#"}>zip code above</Link>
                  </span>{" "}
                  to check Xfinity availability in your area.
                </p>
              </div>
            </div>
          </div>

          <div className="accordion">
            <div className="item">
              <h3 className="title" onClick={() => accordionHandler(2)}>
                <span>{faq === 2 ? "-" : "+"}</span> Xfinity TV, internet and
                home phone bundles (northeast)
              </h3>

              <div className={`faq-content ${faq === 2 ? "show" : ""}`}>
                <p>
                  The cheapest Xfinity internet plan is 75 Mbps for $19.99 in
                  the West region, 75 Mbps for $30.00 in the Central region, and
                  200 Mbps for $25.00/mo. in the Northeast region.
                  Unfortunately, plan pricing and naming varies by region.
                </p>
              </div>
            </div>
          </div>

          <div className="accordion">
            <div className="item">
              <h3 className="title" onClick={() => accordionHandler(3)}>
                <span>{faq === 3 ? "-" : "+"}</span> Which Xfinity region i’m
                in?
              </h3>

              <div className={`faq-content ${faq === 3 ? "show" : ""}`}>
                <div className="custom-table">
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th>West region</th>
                        <th>Northeast region</th>
                        <th>Central region</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>1.</td>
                        <td>Arizona</td>
                        <td>Alabama</td>
                        <td>Connecticut</td>
                      </tr>
                      <tr>
                        <td>2.</td>
                        <td>California</td>
                        <td>Arkansas</td>
                        <td>Delaware</td>
                      </tr>
                      <tr>
                        <td>3.</td>
                        <td>Colorado</td>
                        <td>Florida</td>
                        <td>District Of Columbia</td>
                      </tr>
                      <tr>
                        <td>4.</td>
                        <td>Idaho</td>
                        <td>Georgia</td>
                        <td>Maine</td>
                      </tr>
                      <tr>
                        <td>5.</td>
                        <td>Kansas</td>
                        <td>Illinois</td>
                        <td>Maryland</td>
                      </tr>
                      <tr>
                        <td>6.</td>
                        <td>Minnesota</td>
                        <td>Indiana</td>
                        <td>Massachusetts</td>
                      </tr>
                      <tr>
                        <td>7.</td>
                        <td>Missouri</td>
                        <td>Kentucky</td>
                        <td>New Hampshire</td>
                      </tr>
                      <tr>
                        <td>8.</td>
                        <td>Oregon</td>
                        <td>Michigan</td>
                        <td>New York</td>
                      </tr>
                      <tr>
                        <td>9.</td>
                        <td>Texas</td>
                        <td>Mississippi</td>
                        <td>North Carolina</td>
                      </tr>
                      <tr>
                        <td>10.</td>
                        <td>Utah</td>
                        <td>South Carolina</td>
                        <td>Ohio</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <br />
                <br />
                <p>
                  Xfinity’s two-service bundles offer a strong mix of value and
                  performance for households that want multiple Xfinity
                  services. With Xfinity’s triple-service bundles, you can add
                  nationwide phone service for an extra $10 per month. Landline
                  phones tend to be the red-headed stepchild for providers these
                  days, but it’s still a decent value if you regularly call
                  overseas or don’t have a cell phone.
                  <br />
                  Xfinity’s bundle with 125 channels and 400 Mbps is our top
                  Xfinity bundle pick. You’ll get an excellent channel lineup
                  along with download speeds that’ll comfortably support a small
                  household’s internet needs. Check out our Xfinity internet and
                  cable TV reviews to learn more about Xfinity’s bundled
                  services.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="endnote is-xfinity-good">
          <div className="endnote-container">
            <img src={quotationsIcon} alt="quotations" />
            <h4>How to choose an Xfinity bundle package</h4>
            <h5>1. How to choose an Xfinity bundle package</h5>
            <p>
              If you’re getting Xfinity internet for yourself or a small
              household that goes online only for Facebook or emails, you’ll be
              fine with a 75 Mbps to 200 Mbps internet plan. But if you have a
              family of heavy internet users, get a plan with download speeds of
              at least 300 Mbps. Check out our internet speed guide to learn
              more about home internet speeds.
            </p>
            <h5>2. What channels are must-haves?</h5>
            <p>
              Xfinity’s TV plans have numerous basic and premium cable channels,
              so make sure your TV package has the networks you’d consider
              must-haves by checking Xfinity’s channel lineups. Xfinity also
              offers TV hardware like the X1 and Flex that come with apps for
              popular streaming services.
            </p>
            <h5>3. Get the best Xfinity bundle price</h5>
            <p>
              Xfinity’s bundles can get pricey, but make sure you’re not
              undercutting your household’s needs to save a few bucks. For
              example, Xfinity has a few TV and internet bundles that only
              include a basic 10-channel local package with networks like ABC
              and FOX. You can typically get these channels for free if you buy
              an over-the-air antenna. (And don’t forget about the local
              broadcast fees that Xfinity has to tack onto its cable plans.)
            </p>
            <h5>4. Keep it (for longer)</h5>
            <p>
              Depending on your plan, certain bundles offer longer two-year
              price guarantees compared to Xfinity’s standalone packages, which
              may only lock in your price for a year before your price goes up.
              While we’re not the biggest fans of Xfinity’s contracts, its
              extended price guarantees are a plus if you’re sticking with
              Xfinity for the long haul.
            </p>
            <br />
          </div>
        </div>
      </div>

      <div className="section xfinity-competition">
        <div className="head">
          <h2 className="sect-heading">
            Xfinity bundles <span> vs. the competition</span>
          </h2>
          <p className="sect-desc">
            Xfinity’s bundles are largely in line with TV and internet bundles
            from competing cable providers. Basic Xfinity plans have a standard
            one-year price guarantee, but in certain regions, you’ll be able to
            lock your bundle price for two years of guaranteed savings.
            <span className="link">
              <Link to={"#"}>Spectrum’s bundle prices </Link>
            </span>
            look cheaper on paper compared to Xfinity, but that’s due to
            Spectrum’s unusual plan pricing. Spectrum offers only one TV plan,
            but you can add à la carte packs for more basic and premium cable
            channels. Once you add a few packs, Spectrum’s bundle prices line up
            with Xfinity’s. (And the less said about Cox’s blindingly expensive
            bundles, the better.)
            <br />
            <br />
            Providers like{" "}
            <span className="link">
              <Link to={"#"}>Optimum </Link>
            </span>{" "}
            and{" "}
            <span className="link">
              <Link to={"#"}>Verizon Fios </Link>
            </span>{" "}
            have faster fiber internet plans and larger channel counts compared
            to Xfinity. Fiber internet is more reliable than cable internet and
            comes with much faster upload speeds. But with both providers,
            you’ll pay slightly more per month for these premium features.
          </p>
        </div>
        <PackageTable item={packageTableDataTwo} />
        <p className="below-table">
          Data effective as of publish date. Offers and availability may vary by
          location and are subject to change.
        </p>
        <div className="endnote review-endnote">
          <div className="endnote-container">
            <img className="quotation-img" src={quotations} alt="quotations" />
            <h4>Xfinity Stream app</h4>
            <p>
              If you’ve got an Xfinity TV and internet package, be sure to
              download the{" "}
              <span className="link">
                <Link to={"#"}>Xfinity Stream app.</Link>
              </span>{" "}
              It lets you watch all your DVR recordings and around 200 channels
              while you’re on the go. Check out our{" "}
              <span className="link">
                <Link to={"#"}>Xfinity Stream app review</Link>
              </span>{" "}
              for all the deets you crave.
            </p>
          </div>
        </div>
      </div>

      <div className="section xfinity-bundle-specs-feature">
        <div className="head">
          <h2 className="sect-heading">
            Xfinity bundles<span> specs and features</span>
          </h2>
          <p className="sect-desc">
            From home security to cell phones, there are a lot of ways to build
            your perfect Xfinity bundle. Let’s break down some of Xfinity’s best
            home phone, TV, and internet bundle features.
          </p>
        </div>
        <div className="package-table-container channels">
          <h4>Xfinity cell phone plans and bundles</h4>
          <p className="after-heading">
            If you love bundling, why stop at Xfinity TV, internet, and home
            phone when you can have Xfinity cell phone service too?
            <br />
            Xfinity Mobile is only for Xfinity internet customers, and it offers
            a couple of middle-of-the-road cell phone plans—nothing too flashy.
            You can also get excellent bundle deals when you combine Xfinity
            Mobile with an Xfinity internet plan and an Xfinity Flex. Check out
            our{" "}
            <span className="link">
              <Link to={"#"}> Xfinity Mobile</Link>
            </span>{" "}
            review to learn more about the service.
          </p>
        </div>
        <div className="package-table-container channels">
          <h4>Xfinity home security bundles</h4>
          <p className="after-heading">
            Xfinity is among the few TV and internet service providers that also
            offer home security. It’s . . . uh . . . not worth spending your
            money. Sure, you get a discount on the service when you bundle.
            That’s nice. And if you’ve got an{" "}
            <span className="link">
              <Link to={"#"}>X1 DVR</Link>
            </span>
            , you can even view your security camera feed right from your TV.
            That’s kinda neat.
            <br />
            The problem for us is that Xfinity’s home security uses professional
            monitoring. No, we’re not wearing tinfoil hats about strangers
            watching your every moment—we’re concerned about trusting your
            safety to Xfinity’s spotty customer service.
            <br />
            For example, talking with a real person at Xfinity is difficult
            because it doesn’t even have a phone line you can call. That’s a
            mild annoyance when you’re trying to get help with your dropped TV
            service, but it could be a big issue if you couldn’t reach Xfinity’s
            security help in an emergency.
            <br />
            Unless you’re on a tight budget for home security, we say skip
            Xfinity’s home security bundles. You deserve better.
            <br />
          </p>
        </div>
        <div className="package-table-container channels">
          <h4>Xfinity equipment options</h4>
          <p className="after-heading">
            Depending on your exact Xfinity bundle, your plan will come with a
            lot of hardware, and in many cases, Xfinity won’t let it go for
            free. Check out our{" "}
            <span className="link">
              <Link to={"#"}>Xfinity equipment guide</Link>
            </span>{" "}
            for a full breakdown of Xfinity’s hardware options.
          </p>
        </div>
      </div>

      <div className="section xfinity-final-take">
        <div className="head">
          <h2 className="sect-heading">
            Final take: <span> Are Xfinity bundles worth it?</span>
          </h2>
          <p className="sect-desc">
            If you need internet and TV, it’s usually more affordable to get
            them from the same provider, and that wisdom holds true with Xfinity
            bundle packages.
            <br />
            <br />
            We still wish Xfinity offered better benefits with its bundles. The
            monthly savings are nice, but we’d love to see perks like included
            equipment or free subscriptions. All things considered, Xfinity
            bundles are run-of-the-mill—which honestly describes everything
            about Xfinity to a T.
          </p>
        </div>
        <div className="all-links">
          <Link to={"#"}>Sign up for xfinity</Link>
        </div>
      </div>

      <div className="section xfinity-tv-bundle-faq">
        <div className="head">
          <h2 className="sect-heading">
            Xfinity <span>FAQ</span>
          </h2>

          <p className="sect-desc">
            TL;DR? Xfinity kills it. For more detailed info on all Xfinity
            equipment and services, see our comprehensive Xfinity equipment
            guide.
          </p>
        </div>

        <div className="faq-wrapper">
          <div className="accordion">
            <div className="item">
              <h3 className="title" onClick={() => accordionHandler(1)}>
                <span>{faq === 1 ? "-" : "+"}</span> What are Xfinity tV
                packages?
              </h3>

              <div className={`faq-content ${faq === 1 ? "show" : ""}`}>
                <p>
                  Xfinity TV packages by Comcast come through a cable connection
                  with up to 185+ channels. Most packages also include 20 hours
                  of DVR storage via the{" "}
                  <span className="link">
                    <Link to={"#"}>X1 DVR box, </Link>
                  </span>{" "}
                  plus the Xfinity Stream app.
                </p>
                <ul>
                  <li>· Choice TV: $20.00–$25.00/mo., 10+ channels</li>
                  <li>· Popular TV: $50.00–$60.00/mo., 125+ channels</li>
                  <li>· Ultimate TV: $68.50–$80.00/mo., 185+ channels</li>
                </ul>
                <p>
                  Check to see what Xfinity TV packages are available in your
                  area.
                </p>
              </div>
            </div>
          </div>

          <div className="accordion">
            <div className="item">
              <h3 className="title" onClick={() => accordionHandler(2)}>
                <span>{faq === 2 ? "-" : "+"}</span> What is the cheapest
                xfinity tV Package?
              </h3>

              <div className={`faq-content ${faq === 2 ? "show" : ""}`}>
                <p>
                  The cheapest Xfinity internet plan is 75 Mbps for $19.99 in
                  the West region, 75 Mbps for $30.00 in the Central region, and
                  200 Mbps for $25.00/mo. in the Northeast region.
                  Unfortunately, plan pricing and naming varies by region.
                </p>
              </div>
            </div>
          </div>

          <div className="accordion">
            <div className="item">
              <h3 className="title" onClick={() => accordionHandler(3)}>
                <span>{faq === 3 ? "-" : "+"}</span> Can you get xfinity tV
                without cable?
              </h3>

              <div className={`faq-content ${faq === 3 ? "show" : ""}`}>
                <p>
                  Sorry, after April 2023, the{" "}
                  <span className="link">
                    <Link to={"#"}>Xfinity Flex </Link>
                  </span>{" "}
                  won't be free anymore. If you don't mind paying whatever its
                  new price will be, you can request it when you sign up for
                  your Xfinity internet plan (although your customer service rep
                  will probably railroad you into getting it before you get a
                  word in edgewise).
                  <br />
                  However, if you get an{" "}
                  <span className="link">
                    <Link to={"#"}>Xfinity TV plan</Link>
                  </span>
                  , you're not eligible for an Xfinity Flex. That's okay-ish
                  since you'll get the X1 DVR instead. Unfortunately, the X1
                  falls into the non-free category as well due to its $14.95 per
                  month service fee.
                </p>
              </div>
            </div>
          </div>

          <div className="accordion">
            <div className="item">
              <h3 className="title" onClick={() => accordionHandler(4)}>
                <span>{faq === 4 ? "-" : "+"}</span> what is the best package to
                get on xfinity tV?
              </h3>

              <div className={`faq-content ${faq === 4 ? "show" : ""}`}>
                <p>
                  Comcast and Xfinity are the same thing-you can't trip us up
                  with your trick question!
                </p>
                <p>
                  In 2010, Comcast started using Xfinity as its trade name for
                  TV, internet, and phone services.
                  <br />
                  And as part of Comcast, Xfinity has built-in relationships
                  with Comcast's other products, such as NBCUniversal. That's
                  one of the reasons you'll get the{" "}
                  <span className="link">
                    <Link to={"#"}>Peacock Premium</Link>
                  </span>{" "}
                  streaming service for free with your Xfinity cable TV service.
                </p>
              </div>
            </div>
          </div>

          <div className="accordion">
            <div className="item">
              <h3 className="title" onClick={() => accordionHandler(5)}>
                <span>{faq === 5 ? "-" : "+"}</span>can you just get xfinity tV?
              </h3>

              <div className={`faq-content ${faq === 5 ? "show" : ""}`}>
                <p>
                  The best Xfinity deal is Superfast Internet + Ultimate TV,
                  which provides 800 Mbps download speeds and 185+ cable
                  channels. Your savings may vary by region, though, and
                  depending on where you live, it could cost $115-$120 a month.
                </p>
                <p>
                  For more on Xfinity's money-saving double plays, head over to
                  our{" "}
                  <span className="link">
                    <Link to={"#"}>Xfinity bundles review</Link>
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className="accordion">
            <div className="item">
              <h3 className="title" onClick={() => accordionHandler(6)}>
                <span>{faq === 6 ? "-" : "+"}</span>what channels are in the
                xfinity’s basic package?
              </h3>

              <div className={`faq-content ${faq === 6 ? "show" : ""}`}>
                <p>
                  According to our{" "}
                  <span className="link">
                    <Link to={"#"}>customer satisfaction survey,</Link>
                  </span>{" "}
                  62% of Xfinity customers are satisfied with their internet
                  service. In addition, 69% of Xfinity customers are satisfied
                  with their internet speed, tying it for first place with
                  Verizon. Cable service will always be dependent on how heavy
                  usage is in your network, so for more reliable speeds, we
                  recommend{" "}
                  <span className="link">
                    <Link to={"#"}>fiber internet</Link>
                  </span>
                  if you have the option.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="divider">
        <p>
          See also: Xfinity Overview | Xfinity Internet | Xfinity TV | Xfinity
          Voice | Xfinity Bundles | Xfinity Channel Lineup | Xfinity Customer
          Service | Xfinity Availability Map | Xfinity Free Installation
        </p>
      </div>

      <div className="section endnote intertnet-review-endnote">
        <div className="endnote-container">
          <img className="quotation-img" src={quotations} alt="quotations" />
          <h4>Methodology:</h4>
          <p>
            To find out how Xfinity’s bundles stacked up to the competition, we
            rated each bundle in areas including value, channel count, and
            potential savings. We also used our annual{" "}
            <span className="link">
              <Link to={"#"}> internet customer satisfaction</Link>
            </span>{" "}
            and{" "}
            <span className="link">
              <Link to={"#"}> TV customer satisfaction</Link>
            </span>{" "}
            surveys to see how real Xfinity customers felt about the provider.
            <br />
            To learn more about our research and testing, check out our{" "} 
            <span className="link">
              <Link to={"#"}> How We Rank </Link>
            </span>
            page.
          </p>
        </div>
      </div>
      <Newsletter />
    </div>
  );
};

export default XfinityBundlesReview;
