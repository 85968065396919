import React, { useState } from "react";
import SubNav from "../../../components/sub-nav/SubNav";
import InnerPagesHero from "../../../components/inner-pages-hero/InnerPagesHero";
import StickyHeader from "../../../components/sticky-header/StickyHeader";
import { xfinityNavLinks } from "../../../assets/data/navLinks/xfinityNavLinks";
import xfinityInternetHeroBg from "../../../assets/images/xfinity-internet-hero-bg.jpg";
import "./xfinity-mobile.scss";
import { Link } from "react-router-dom";
import { bestOffersTemporary } from "../../../assets/data/bestOffersTemporary";
import { PackageCard } from "../../../components/package-card/PackageCard";
import CTA from "../../../components/cta-section/CTA";
import PackageTable from "../../../components/package-table/PackageTable";
import Newsletter from "../../../components/newsletter/Newsletter";

const XfinityMobile = () => {
  const [faq, setFaq] = useState(null);

  const heroContent = {
    img: xfinityInternetHeroBg,
    heading: "Xfinity Mobile Plans, Packages, And Deals 2023",
    desc: "Lorem ipsum dolor sit amet consectetur. Dolor facilisis vel nunc faucibus elementum. Tortor dui massa lacus sem scelerisque morbi sit dui. Ut et diam dui aliquam vitae aliquam.",
    points: [
      "Save up to $1,000 on Samsung Galaxy phones",
      "Up to 50 GB of high-speed mobile data",
      "Nationwide 5G coverage for fast downloads",
      "Mix and match phone plans for more savings",
    ],
    buttonName: "View plans",
    buttonPath: "#",
    price: "TV packages starting at $15.00/mo.",
  };

  let packageTableData = [
    {
      head: [
        "Plan",
        "Monthly price (single line)",
        "Monthly price (2–4 lines) speeds",
        "Monthly price (5–10 lines)",
        "Data allowance",
        "Details",
      ],

      body: [
        {
          content: [
            "1.",
            "By The Gig",
            "$15.00/mo.",
            "$15.00/mo.",
            "$15.00/mo.",
            "10 GB",
          ],
          linkPath: "#",
        },

        {
          content: [
            "2.",
            "By The Gig",
            "$15.00/mo.",
            "$15.00/mo.",
            "$15.00/mo.",
            "10 GB",
          ],
          linkPath: "#",
        },

        {
          content: [
            "3.",
            "By The Gig",
            "$15.00/mo.",
            "$15.00/mo.",
            "$15.00/mo.",
            "10 GB",
          ],
          linkPath: "#",
        },

        {
          content: [
            "4.",
            "By The Gig",
            "$15.00/mo.",
            "$15.00/mo.",
            "$15.00/mo.",
            "10 GB",
          ],
          linkPath: "#",
        },
      ],
    },
  ];

  let accordionHandler = (i) => {
    if (faq === i) {
      return setFaq(null);
    }

    setFaq(i);
  };

  return (
    <div className="xfinity-mobile">
      <SubNav links={xfinityNavLinks} />
      <InnerPagesHero data={heroContent} />
      <StickyHeader />

      <div className="section xfinity-deals-and-promotions">
        <div className="head">
          <h2 className="sect-heading">
            Top Xfinity <span>deals and promotions</span>
          </h2>
          <p className="sect-desc">
            <span>
              <Link to={"#"}>Xfinity</Link>
            </span>{" "}
            is available almost everywhere in the US. In some areas, it’s the
            only cable TV and internet service you can get. That’s not
            necessarily a bad thing, as Xfinity’s new customers can choose from
            several excellent and relatively inexpensive plans and packages
            (and, let’s be honest, a few strikingly pricey ones). <br /> Whether
            you’re going cheap or premium with your Xfinity service, we’ve
            rounded up several Xfinity bundles and promotions and other ways to
            save a few bucks on your monthly bill.
          </p>
        </div>

        <div className="cards">
          {bestOffersTemporary.map((item, index) => (
            <PackageCard
              key={index}
              img={item.img}
              tagName={item.tagName}
              packageName={item.packageName}
              packagePoints={item.packagePoints}
              price={item.price}
              linkPath={item.linkPath}
            />
          ))}
        </div>

        <p className="bottom-line">
          *Price with two or more phone lines. Data effective as of publish
          date. Offers and availability may vary by location and are subject to
          change.
        </p>
      </div>

      <CTA
        heading={"Interested in Xfinity service?"}
        desc={
          "Xfinity Mobile requires an Xfinity internet plan. Enter your zip code below to see if Xfinity offers internet service in your area."
        }
      />

      <div className="section xfinity-mobile-pricing">
        <div className="head">
          <h2 className="sect-heading">
            Xfinity <span>Mobile pricing:</span> pay less and get more
          </h2>
          <p className="sect-desc">
            Interested in a smaller phone bill? Xfinity Mobile offers great cell
            phone plans exclusively for Xfinity Internet customers. If you
            simply need cell phone service, By the Gig’s price can’t be beaten.
            With By the Gig, you’ll pay one flat monthly rate for cell phone
            service and mobile data that’s shareable across multiple lines.
            Plus, you’ll get standard Xfinity Mobile features like HD video
            streaming and 5G coverage for the fastest download speeds. <br />
            <br /> Xfinity Mobile’s three Unlimited plans offer the best value
            if you regularly scroll TikTok or watch YouTube on your smartphone.
            With up to 50 GB of monthly high-speed data, you won’t have to worry
            about overage fees on Xfinity Mobile. Large families can also save
            more on Xfinity Mobile thanks to its line discounts—add more lines
            to your account, and you’ll pay less per month.
          </p>
        </div>

        <PackageTable item={packageTableData} />
      </div>

      <div className="section xfinity-mobile-faq">
        <div className="head">
          <h2 className="sect-heading">
            Xfinity <span>Mobile</span> FAQ
          </h2>

          <p className="sect-desc">
            TL;DR? Xfinity kills it. For more detailed info on all Xfinity
            equipment and services, see our comprehensive Xfinity equipment
            guide.
          </p>
        </div>

        <div className="faq-wrapper">
          <div className="accordion">
            <div className="item">
              <h3 className="title" onClick={() => accordionHandler(1)}>
                <span>{faq === 1 ? "-" : "+"}</span> What are Xfinity tV
                packages?
              </h3>

              <div className={`faq-content ${faq === 1 ? "show" : ""}`}>
                <p>
                  Xfinity TV packages by Comcast come through a cable connection
                  with up to 185+ channels. Most packages also include 20 hours
                  of DVR storage via the X1 DVR box, plus the Xfinity Stream
                  app.
                </p>
                <ul>
                  <li>
                    <span className="bold">Choice TV: </span>
                    $20.00–$25.00/mo., 10+ channels
                  </li>
                  <li>
                    <span className="bold">Popular TV: </span>
                    $50.00–$60.00/mo., 125+ channels
                  </li>
                  <li>
                    <span className="bold">Ultimate TV: </span>
                    $68.50–$80.00/mo., 185+ channels
                  </li>
                </ul>

                <p>
                  Check to see what Xfinity TV packages are available in your
                  area.
                </p>
              </div>
            </div>
          </div>

          <div className="accordion">
            <div className="item">
              <h3 className="title" onClick={() => accordionHandler(2)}>
                <span>{faq === 2 ? "-" : "+"}</span> What is the cheapest
                Xfinity TV package?
              </h3>

              <div className={`faq-content ${faq === 2 ? "show" : ""}`}>
                <p>
                  The cheapest Xfinity TV package is{" "}
                  <span className="bold"> Choice TV</span>, a basic cable
                  package that offers 10+ channels for $20.00–$25.00/mo.
                </p>
                <p>
                  The cheapest regular cable package is{" "}
                  <span className="bold"> Popular TV</span> ($50.00–$60.00/mo.,
                  125+ channels).
                </p>
                <p>
                  Looking for more ways to cut monthly costs? We like the way
                  you think! View Xfinity’s{" "}
                  <span className="link">
                    <Link to={"#"}>current deals and promotions</Link>
                  </span>
                  .
                </p>
              </div>
            </div>
          </div>

          <div className="accordion">
            <div className="item">
              <h3 className="title" onClick={() => accordionHandler(3)}>
                <span>{faq === 3 ? "-" : "+"}</span> What are Xfinity tV
                packages?
              </h3>

              <div className={`faq-content ${faq === 3 ? "show" : ""}`}>
                <p>
                  Xfinity TV packages by Comcast come through a cable connection
                  with up to 185+ channels. Most packages also include 20 hours
                  of DVR storage via the X1 DVR box, plus the Xfinity Stream
                  app.
                </p>
                <ul>
                  <li>
                    <span className="bold">Choice TV: </span>
                    $20.00–$25.00/mo., 10+ channels
                  </li>
                  <li>
                    <span className="bold">Popular TV: </span>
                    $50.00–$60.00/mo., 125+ channels
                  </li>
                  <li>
                    <span className="bold">Ultimate TV: </span>
                    $68.50–$80.00/mo., 185+ channels
                  </li>
                </ul>

                <p>
                  Check to see what Xfinity TV packages are available in your
                  area.
                </p>
              </div>
            </div>
          </div>

          <div className="accordion">
            <div className="item">
              <h3 className="title" onClick={() => accordionHandler(4)}>
                <span>{faq === 4 ? "-" : "+"}</span> What are Xfinity tV
                packages?
              </h3>

              <div className={`faq-content ${faq === 4 ? "show" : ""}`}>
                <p>
                  Xfinity TV packages by Comcast come through a cable connection
                  with up to 185+ channels. Most packages also include 20 hours
                  of DVR storage via the X1 DVR box, plus the Xfinity Stream
                  app.
                </p>
                <ul>
                  <li>
                    <span className="bold">Choice TV: </span>
                    $20.00–$25.00/mo., 10+ channels
                  </li>
                  <li>
                    <span className="bold">Popular TV: </span>
                    $50.00–$60.00/mo., 125+ channels
                  </li>
                  <li>
                    <span className="bold">Ultimate TV: </span>
                    $68.50–$80.00/mo., 185+ channels
                  </li>
                </ul>

                <p>
                  Check to see what Xfinity TV packages are available in your
                  area.
                </p>
              </div>
            </div>
          </div>

          <div className="accordion">
            <div className="item">
              <h3 className="title" onClick={() => accordionHandler(5)}>
                <span>{faq === 5 ? "-" : "+"}</span> What are Xfinity tV
                packages?
              </h3>

              <div className={`faq-content ${faq === 5 ? "show" : ""}`}>
                <p>
                  Xfinity TV packages by Comcast come through a cable connection
                  with up to 185+ channels. Most packages also include 20 hours
                  of DVR storage via the X1 DVR box, plus the Xfinity Stream
                  app.
                </p>
                <ul>
                  <li>
                    <span className="bold">Choice TV: </span>
                    $20.00–$25.00/mo., 10+ channels
                  </li>
                  <li>
                    <span className="bold">Popular TV: </span>
                    $50.00–$60.00/mo., 125+ channels
                  </li>
                  <li>
                    <span className="bold">Ultimate TV: </span>
                    $68.50–$80.00/mo., 185+ channels
                  </li>
                </ul>

                <p>
                  Check to see what Xfinity TV packages are available in your
                  area.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Newsletter />
    </div>
  );
};

export default XfinityMobile;
